import {apiSlice} from "../../api/apiSlice";
import {Tags} from "../../api/constants";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {ContextUtils} from "../contexts";

const contextsAdapter = createEntityAdapter({
    sortComparer: ContextUtils.sortComparer
})

const initialState = contextsAdapter.getInitialState();

const contextApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        
        getContexts: builder.query({
            query: _ => ({
                url: "/context",
                method: "GET"
            }),
            transformResponse: data => contextsAdapter.setAll(initialState, data),
            providesTags: [Tags.contexts]
        }),
        
        createContext: builder.mutation({
            query: context => ({
                url: "/context",
                method: "POST",
                body: context
            }),
            invalidatesTags: [Tags.contexts]
        }),
        
        updateContext: builder.mutation({
            query: context => ({
                url: "/context",
                method: "PUT",
                body: context
            }),
            invalidatesTags: [Tags.contexts]
        })
    })
})

export const {
    useGetContextsQuery,
    useCreateContextMutation,
    useUpdateContextMutation
} = contextApiSlice;

// selectors

const selectContextsResult = contextApiSlice.endpoints.getContexts.select();

export const selectContextsData = createSelector(
    selectContextsResult,
    contextsResult => contextsResult.data);

export const {
    selectIds: selectContextsIds,
    selectAll: selectAllContexts,
    selectById: selectContextById
} = contextsAdapter.getSelectors(state => selectContextsData(state) ?? initialState);

export const selectContextsByParentId = createSelector(
    (state, _) => selectContextsData(state),
    (_, parentId) => parentId,
    (data, parentId) => data.ids.filter(id => data.entities[id].parentId === parentId).map(id => data.entities[id])
);