import React from 'react';
import {RouterProvider} from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


export const App = ({ router }) => {
    
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
            <RouterProvider router={router} >
                
                // TODO remove
                {/*<Layout>*/}
                {/*    <Routes>*/}
                {/*      {AppRoutes.map((route, index) => {*/}
                {/*        const { element, requireAuth, ...rest } = route;*/}
                {/*        return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;*/}
                {/*      })}*/}
                {/*    </Routes>*/}
                {/*</Layout>*/}
                
            </RouterProvider>
        </LocalizationProvider>
    );
}
