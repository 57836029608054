import {Glyphicon} from "../../../common/glyphs";
import {useSetThingDoneMutation} from "../slices/thingApiSlice";
import {ToolForm} from "../../shared/ToolForm";

export const CompleteThingBtn = ( {thing, onComplete, ...rest}) => {
    
    const [save] = useSetThingDoneMutation();
    
    const handleSubmit = async (thing) => {
        
        await save({
            id: thing.id,
            done: true
        })
        
        onComplete && onComplete(thing);
    };

    return <ToolForm entity={thing} onSubmit={handleSubmit} icon={Glyphicon.complete} title="Готово" {...rest}/>;
}