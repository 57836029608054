import {Glyphicon} from "../../../common/glyphs";
import {ToolLink} from "../../shared/ToolLink";
import {UncompleteThingBtn} from "./UncompleteThingBtn";
import {CompleteThingBtn} from "./CompleteThingBtn";
import {UndeleteThingBtn} from "./UndeleteThingBtn";
import {DeleteThingBtn} from "./DeleteThingBtn";

export const ThingToolbar = ({thing, onComplete, onDelete}) => {

    const completeTool = thing.done
        ? <UncompleteThingBtn entity={thing} className="me-1"/>
        : <CompleteThingBtn thing={thing} onComplete={onComplete} className="me-1"/>

    const deleteTool = thing.deleted
        ? <UndeleteThingBtn entity={thing} className="me-1" />
        : <DeleteThingBtn thing={thing} onDelete={onDelete} className="me-1" />;
    
    return (
        <div className="btn-toolbar">
            {completeTool}
            {deleteTool}
            <ToolLink to={`/thing/${thing.id}/edit/`} icon={Glyphicon.edit} title="Редактировать" />
        </div>
    );
}