import {Glyphicon} from "../../../common/glyphs";
import {useSetThingDoneMutation} from "../slices/thingApiSlice";
import {ToolForm} from "../../shared/ToolForm";

export const UncompleteThingBtn = ({entity, ...rest}) => {
    
    const [ setDone, { isLoading } ] = useSetThingDoneMutation();
    
    const handleSubmit = async (entity) => {

        await setDone({
            id: entity.id,
            done: false
        })
    };

    return <ToolForm entity={entity} onSubmit={handleSubmit} icon={Glyphicon.uncomplete} title="Не готово" {...rest}/>;
}