export const Filter = {

    completed: 'completed',
    deleted: 'deleted',

    getTitle: function(filter) {

        switch (filter) {

            case Filter.completed:
                return 'Завершённые';

            case Filter.deleted:
                return 'Удалённые';
        }
    },

    getIconName: function (filter) {

        switch (filter) {

            case Filter.completed:
                return 'complete';

            case Filter.deleted:
                return 'trash';
        }
    }
}

export const ThingPlannerFilterType = {

    showAll: 'ShowAll',
    hideAll: 'HideAll',
    showProject: 'ShowProject',
    showContext: 'ShowContext'
}