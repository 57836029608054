import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectContextById, useUpdateContextMutation} from "./slices/contextApiSlice";

export const ContextEdit = () => {
    
    const { id: contextId } = useParams();
    const context = useSelector(state => selectContextById(state, contextId));
    const [ title, setTitle ] = useState(context?.title);
    const [ save ] = useUpdateContextMutation();
    const navigate = useNavigate();
    
    if (!context) return null;
    
    const handleSubmit = async (event) => {
        
        event.preventDefault();
        
        const updatedContext = {
            ...context,
            title
        }

        navigate(`/context/${context.id}`);
        await save(updatedContext);
    }
    
    const handleTitleChanged = (event) => {
        setTitle(event.target.value);
    }
    
    return (
        <div className="col-xs-12">
            <form method="POST" onSubmit={handleSubmit}>
                <div className="form-floating mb-2">
                    <input id="title" className="form-control" value={title} onChange={handleTitleChanged} placeholder="Заголовок"/>
                    <label htmlFor="title">Заголовок</label>
                </div>
                <button className="btn btn-outline-secondary" type="submit">Сохранить</button>
            </form>
        </div>
    );
} 