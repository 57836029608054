import {ThingLabel} from "./ThingLabel";
import {Glyphicon} from "../../common/glyphs";
import {LabelColor} from "../../common/colors";
import {useSelector} from "react-redux";
import {selectContextById} from "../contexts/slices/contextApiSlice";
import {selectProjectById} from "../projects/slices/projectApiSlice";
import {formatDate, isBeforeToday} from "../../common/date-time";
import {Scheduler} from "../schedulers/schedulers";

export const ThingLabels = ({ thing, hideLabels }) => {

    const context = useSelector(state => selectContextById(state, thing?.contextId));
    const project = useSelector(state => selectProjectById(state, thing?.projectId));

    hideLabels = {
        ...hideLabels,
        project: !thing.projectId || hideLabels?.project,
        context: !thing.contextId || hideLabels?.context,
        description: !thing.description || hideLabels?.description
    };

    const deleteDateLabel = thing.deleted ? <ThingLabel icon={Glyphicon.trash} text={formatDate(thing.whenDeleted)} /> : null;
    const doneDateLabel = thing.done ? <ThingLabel icon={Glyphicon.complete} text={formatDate(thing.whenDone)}/> : null;
    const schedulerLabel = !hideLabels.scheduler ? <ThingLabel icon={Glyphicon[Scheduler.getIconName(thing.schedulerType)]} text={Scheduler.getTitle(thing.schedulerType)} /> : null;
    const descriptionLabel = !hideLabels.description ? <ThingLabel icon={Glyphicon.description} title={thing.description.length > 100 ? thing.description.substring(0, 100) + "..." : thing.description} /> : null;

    let startDateLabel;

    if (thing.start) {

        const colorName = !thing.done && isBeforeToday(thing.start) ? LabelColor.danger : LabelColor.default;
        startDateLabel = <ThingLabel color={colorName} icon={Glyphicon.calendar} text={formatDate(thing.start)}/>;
    }

    const contextLabel =
        !hideLabels.context && context
            ? <ThingLabel icon={Glyphicon.mapMarker} text={context.title}/>
            : null;

    const projectLabel =
        !hideLabels.project && project
            ? <ThingLabel icon={Glyphicon.briefcase} text={project.title}/>
            : null;
    
    return <>{deleteDateLabel}{doneDateLabel}{startDateLabel} {descriptionLabel} {schedulerLabel} {contextLabel} {projectLabel}{/* В одну строку через пробел, чтобы метки не «слипались». */}</>;
}