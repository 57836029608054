import {groupBy} from "../../common/groupping";
import {ThingsGroup} from "./ThingsGroup";
import {Glyphicon} from "../../common/glyphs";
import {period} from "../../common/date-time";
import {By} from "../../common/sortings";

const groupByImpl  = groupBy;

export const ThingsGroupedByPeriod = ({things, hideLabels, onComplete}) => {

    const groups = groupByImpl(things, thing => period.get(thing.start));
    const sortedGroups = Array.from(groups.keys()).sort((a, b) => a.sortOrder - b.sortOrder);
    
    return sortedGroups?.map(group =>
        <ThingsGroup key={group.id} group={group.title} icon={Glyphicon.calendar}
                     things={groups.get(group)} sortBy={By.start} hideLabels={hideLabels} onComplete={onComplete}/>
    );
}