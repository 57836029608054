import {Glyphicon} from "../../../common/glyphs";
import {ToolForm} from "../../shared/ToolForm";

export const UndeleteThingBtn = ( {thing, ...rest}) => {
    
    const handleSubmit = async (event) => {

        // TODO
    };

    return <ToolForm entity={thing} onSubmit={handleSubmit} icon={Glyphicon.undelete} title="Восстановить" {...rest}/>;
}