import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectProjectById, useUpdateProjectMutation} from "./slices/projectApiSlice";
import {useState} from "react";

export const ProjectEdit = () => {
    
    const { id:projectId } = useParams();
    const project = useSelector(state => selectProjectById(state, projectId));
    const [title, setTitle] = useState(project?.title);
    const [description, setDescription] = useState(project?.description);
    const [ save ] = useUpdateProjectMutation();
    const navigate = useNavigate();
    
    if(!project) return null;
    
    const handleCancel = (event) => {
        
        event.preventDefault();
        navigate(-1);
    }
    
    const handleSubmit = async (event) => {
        
        event.preventDefault();
        
        const updatedProject = {
            ...project,
            title,
            description
        }

        navigate(`/project/${project.id}`);
        await save(updatedProject);
    }
    
    const handleTitleChange = (event) => {
        
        setTitle(event.target.value);
    }
    
    const handleDescriptionChange = (event) => {
        
        setDescription(event.target.value);
    }
    
    return (
        <div className="col-xs-12">
            <form method="POST" onSubmit={handleSubmit}>
                <div className="form-floating mb-2">
                    <input id="title" className="form-control" value={title} onChange={handleTitleChange} placeholder="Заголовок"/>
                    <label htmlFor="title">Заголовок</label>
                </div>
                <div className="form-floating mb-2">
                    <textarea id="description" className="form-control textarea-rows-5" value={description} onChange={handleDescriptionChange} placeholder="Описание"></textarea>
                    <label htmlFor="description">Описание</label>
                </div>
                <button className="btn btn-outline-secondary me-2" type="submit">Сохранить</button>
                <button className="btn btn-outline-secondary" type="button" onClick={handleCancel}>Отмена</button>
            </form>
        </div>
    );
}


// var ProjectEdit = React.createClass({
//
//     getProjectId: function () { return this.props.match.params.id },
//
//     getInitialState: function () {
//
//         var project = this.props.projects.byId[this.getProjectId()];
//
//         if (!project) return {
//            
//             title: '',
//             description: ''
//         };
//
//         return Object.assign({}, project);
//     },
//
//     handleTitleChange: function(e) {
//
//         this.setState({ title: e.target.value });
//     },
//
//     handleDescriptionChange: function (e) {
//
//         this.setState({ description: e.target.value });
//     },
//
//     handleSubmit: function (e) {
//
//         e.preventDefault();
//         this.props.onSubmit && this.props.onSubmit(this.state);
//     },
//
//     componentWillReceiveProps: function(nextProps) {
//
//         if (this.state.id) return;
//
//         var project = nextProps.projects.byId[this.getProjectId()];
//
//         if (!project) return;
//
//         this.setState(Object.assign({}, project));
//     },
//
//     render: function () {
//
//         return  <div className="col-xs-12">
//                     <form method="POST" onSubmit={this.handleSubmit}>
//                         <div className="form-group">
//                             <label htmlFor="title">Заголовок</label>
//                             <input id="title" className="form-control" value={this.state.title} onChange={this.handleTitleChange} />
//                         </div>
//                         <div className="form-group">
//                             <label htmlFor="description">Описание</label>
//                             <textarea id="description" className="form-control" rows="10" value={this.state.description} onChange={this.handleDescriptionChange}></textarea>
//                         </div>
//                         <button className="btn btn-default" type="submit">Сохранить</button>
//                     </form>
//                 </div>;
//     }
// });
//
// var mapProjectEditStateToProps = function (state) {
//
//     return {
//
//         projects: state.projects
//     }
// };
//
// var mapProjectEditDispatchToProps = function (dispatch) {
//
//     return {
//
//         onSubmit: function (project) {
//
//             dispatch(ActionCreator.projectEdit(project));
//         }
//     }
// };
//
// ProjectEdit = ReactRedux.connect(mapProjectEditStateToProps, mapProjectEditDispatchToProps)(ProjectEdit);