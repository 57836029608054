import {Thing} from "./Thing";

export const ThingsList = ( { things, sortBy, hideLabels, onComplete }) => {

    return (
        <>
            <div id="things">
                 {things?.sort(sortBy).map(thing => <Thing key={thing.id} thing={thing} hideLabels={hideLabels} onComplete={onComplete} />)}
             </div>
        </>
    );
}