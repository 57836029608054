import dayjs from "dayjs";
import 'dayjs/locale/ru';
import utc from "dayjs/plugin/utc";
import isTodayInner from "dayjs/plugin/isToday";
import isTomorrowInner from "dayjs/plugin/isTomorrow";

dayjs.locale("ru");
dayjs.extend(utc);
dayjs.extend(isTodayInner);
dayjs.extend(isTomorrowInner);

export const parseUtcAsLocal = (nullableString) => {
    
    return nullableString ?  dayjs.utc(nullableString).local() : null;
}

// Date formatting

export const formatUtc = (value) => {
    
    return value.utc().format();
}

export const formatDate = (utcString) => {
    
    return dayjs.utc(utcString).local().format("DD.MM.YYYY");
}

// Get some highlight dates

export const today = () => {
    
    return dayjs().startOf("day");
}

export const tomorrow = () => {

    return dayjs().startOf("day").add(1, "day");
}

export const afterTomorrow = () => {

    return dayjs().startOf("day").add(2, "day");
}

export const endOfWeek = () => {
    
    return dayjs().endOf("week");
}

export const endOfMonth = () => {

    return dayjs().endOf("month");
}

// Check if date is some particular day

export const isToday = (utcString) => {
    
    return dayjs.utc(utcString).local().isToday();
}

export const isTomorrow = (utcString) => {
    
    return dayjs.utc(utcString).local().isTomorrow();
}

export const isBeforeToday = (utcString) => {
    
    return dayjs.utc(utcString).isBefore(today());
}

// Periods

export const period = {

    empty: {
        id: "empty",
        sortOrder: 0,
        title: "Дата не указана"
    },
    thisWeek: {
        id: "this-week",
        sortOrder: 1,
        title: "На этой неделе"
    },
    thisMonth: {
        id: "this-month",
        sortOrder: 2,
        title: "В этом месяце"
    },
    later: {
        id: "later",
        sortOrder: 3,
        title: "В следующем месяце и позже"
    },

    get: function(dateUtcString) {

        if (!dateUtcString) return period.empty;

        const dateLocal = parseUtcAsLocal(dateUtcString);
        
        if (dateLocal <= endOfWeek()) return period.thisWeek;
        if (dateLocal <= endOfMonth()) return period.thisMonth;
        
        return period.later;
    },

    // TODO remove
    getOrder: function(period) {

        switch (period) {
            case period.thisWeek:
                return 0;
            case period.thisMonth:
                return 1;
            case period.later:
                return 2;
        }

        return undefined;
    },

    // TODO remove
    getTitle: function(period) {

        switch (period) {
            case period.thisWeek:
                return "На этой неделе";
            case period.thisMonth:
                return "В этом месяце";
            case period.later:
                return "В следующем месяце и позже";
        }

        return undefined;
    }
}