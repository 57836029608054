import {Server} from "../server";

export const Actions = function() {

    return {

        userLoad: 'user_load',
        schedulerClick: 'scheduler_click',

        // Common
        commonSelectedChange: 'common_selected_change',
        commonHideInactiveProjects: 'common_hide_inactive_projects',
        commonShowInactiveProjects: 'common_show_inactive_projects',

        // Contact

        contactListLoad: 'contact_list_load',

        // Project

        projectListLoad: 'project_list_load',
        projectCreate: 'project_create',
        projectEdit: 'project_edit',
        projectComplete: 'project_complete',
        projectShare: 'project_share',
        projectDeactivate: 'project_deactivate',

        // Context

        contextListLoad: 'context_list_load',
        contextCreate: 'context_create',
        contextEdit: 'context_edit',
        contextDelete: 'context_delete',
        contextShare: 'context_share',
        contextJoin: 'context_join',
        contextUnjoin: 'context_unjoin',

        // Thing

        thingListLoad: 'thing_list_load',
        thingListPlanned: 'thing_list_planned',
        thingCompletedLoading: 'thing_completed_loading',
        thingCompletedLoad: 'thing_completed_load',
        thingDeletedLoading: 'thing_deleted_loading',
        thingDeletedLoad: 'thing_deleted_load',
        thingAdd: 'thing_create',
        thingComplete: 'thing_complete',
        thingUncomplete: 'thing_uncomplete',
        thingDelete: 'thing_delete',
        thingUndelete: 'thing_undelete',
        thingEdit: 'thing_edit',
        
        // ThingPlanner

        thingPlannerListLoad: 'thing_planner_list_load',
        thingPlannerCreate: 'thing_planner_create',
        thingPlannerComplete: 'thing_planner_complete',
        thingPlannerUncomplete: 'thing_planner_uncomplete',
        thingPlannerEdit: 'thing_planner_edit',
        thingPlannerShowAll: 'thing_planner_show_all',
        thingPlannerHideAll: 'thing_planner_hide_all',
        thingPlannerShowProject: 'thing_planner_show_project',
        thingPlannerShowContext: 'thing_planner_show_context',
        
    }

}();

export const ActionCreator = function() {

    const userLoadResolved = function (user, error) {

        return {
            type: Actions.userLoad,
            payload: {
                user: user
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    // Contact

    const contactListLoadResolved = function (contacts, error) {

        return {
            type: Actions.contactListLoad,
            payload: {
                contacts: contacts
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        };
    };

    // Project

    const projectListLoadResolved = function (projects, error) {

        return {
            type: Actions.projectListLoad,
            payload: {
                projects: projects
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        };
    };

    const projectCreateResolved = function (project, error) {

        return {
            type: Actions.projectCreate,
            payload: {
                project: project
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const projectEditResolved = function (project, error) {

        return {
            type: Actions.projectEdit,
            payload: {project},
            error: error !== undefined,
            meta: {error}
        }
    };

    const projectCompleteResolved = function (result, error) {

        return {
            type: Actions.projectComplete,
            payload: result,
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const projectShareResolved = function (error) {

        return {
            type: Actions.projectShare,
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    // Context

    const contextListLoadResolved = function (contexts, error) {

        return {
            type: Actions.contextListLoad,
            payload: {
                contexts: contexts
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        };
    };

    const contextCreateResolved = function (context, error) {

        return {
            type: Actions.contextCreate,
            payload: {
                context: context
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const contextEditResolved = function (context, error) {

        return {
            type: Actions.contextEdit,
            payload: {
                context: context
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const contextDeleteResolved = function (result, error) {

        return {
            type: Actions.contextDelete,
            payload: result,
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const contextShareResolved = function (error) {

        return {
            type: Actions.contextShare,
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const contextJoinResolved = function (contexts, error) {

        return {
            type: Actions.contextJoin,
            payload: {
                contexts: contexts
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const contextUnjoinResolved = function (context, error) {

        return {
            type: Actions.contextUnjoin,
            payload: {
                context: context
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    // Thing

    const thingListLoadResolved = function (things, error) {

        return {
            type: Actions.thingListLoad,
            payload: {
                things: things
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        };
    };

    const thingCompletedLoadResolved = function (data, error) {

        return {
            type: Actions.thingCompletedLoad,
            payload: {
                things: data.things
            },
            error: error !== undefined,
            meta: {
                error: error,
                pageNumber: data.pageNumber,
                pageCount: data.pageCount,
                thingCount: data.thingCount
            }
        };
    };

    const thingDeletedLoadResolved = function (data, error) {

        return {
            type: Actions.thingDeletedLoad,
            payload: {
                things: data.things
            },
            error: error !== undefined,
            meta: {
                error: error,
                pageNumber: data.pageNumber,
                pageCount: data.pageCount,
                thingCount: data.thingCount
            }
        };
    };

    const thingAddResolved = function (thing, error) {

        return {
            type: Actions.thingAdd,
            payload: {
                thing: thing
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const thingCompleteResolved = function (thing, error) {

        return {
            type: Actions.thingComplete,
            payload: {
                thing: thing
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const thingUncompleteResolved = function (thing, error) {

        return {
            type: Actions.thingUncomplete,
            payload: {
                thing: thing
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const thingDeleteResolved = function (thing, error) {

        return {
            type: Actions.thingDelete,
            payload: {
                thing: thing
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const thingUndeleteResolved = function (thing, error) {

        return {
            type: Actions.thingUndelete,
            payload: {
                thing: thing
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const thingEditResolved = function (thing, error) {

        return {

            type: Actions.thingEdit,
            payload: {
                thing: thing
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    // ThingPlanner

    const thingPlannerListLoadResolved = function (planners, error) {

        return {
            type: Actions.thingPlannerListLoad,
            payload: {
                thingPlanners: planners
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        };
    };

    const thingPlannerCreateResolved = function (thingPlanner, error) {

        return {
            type: Actions.thingPlannerCreate,
            payload: {
                thingPlanner
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const thingPlannerCompleteResolved = function (planner, error) {

        return {

            type: Actions.thingPlannerComplete,
            payload: {
                thingPlanner: planner
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const thingPlannerUncompleteResolved = function (thingPlanner, error) {

        return {

            type: Actions.thingPlannerUncomplete,
            payload: {
                thingPlanner
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    const thingPlannerEditResolved = function (thingPlanner, error) {

        return {

            type: Actions.thingPlannerEdit,
            payload: {
                thingPlanner
            },
            error: error !== undefined,
            meta: {
                error: error
            }
        }
    };

    return {
        
        userLoad: function () {

            return function(dispatch) {
                
                Server.user({
                    success: function (user) {

                        dispatch(userLoadResolved(user));
                    },
                    error: function (xhr, status, err) {
                        // TODO
                    }
                });
            }
        },

        schedulerClick: function (scheduler) {

            return {

                type: Actions.schedulerClick,
                scheduler: scheduler
            }
        },

        // Common

        commonSelectedChange: function (selected) {

            return {
                type: Actions.commonSelectedChange,
                selected: selected
            }
        },

        commonHideInactiveProjects: function () {

            return {
                type: Actions.commonHideInactiveProjects
            }
        },

        commonShowInactiveProjects: function () {

            return {
                type: Actions.commonShowInactiveProjects
            }
        },


        // Соntact

        contactListLoad: function () {

            return function (dispatch) {

                Server.contacts({
                    success: function (contacts) {
                        dispatch(contactListLoadResolved(contacts));
                    },
                    error: function (xhr, status, err) {
                        dispatch(contactListLoadResolved(null, new Error()));
                    }
                });
            }
        },

        // Project

        projectListLoad: function () {
            
            return function(dispatch) {

                Server.projects({
                    success: function(projects) {
                        dispatch(projectListLoadResolved(projects));
                    },
                    error: function (xhr, status, err) {
                        dispatch(projectListLoadResolved(null, new Error()));
                    }
                });
            }
        },

        projectCreate: function (project) {

            return function (dispatch) {

                const args = {
                    project: project,
                    success: function (savedProject) {

                        dispatch(projectCreateResolved(savedProject));
                    },
                    error: function (xhr, status, err) {

                        dispatch(projectCreateResolved(null, new Error()));
                    }
                };

                Server.projectCreate(args);
            }
        },

        projectEdit: function (project) {

            return function (dispatch) {

                const args = {
                    project: project,
                    success: function (savedProject) {

                        dispatch(projectEditResolved(savedProject));
                    },
                    error: function (xhr, status, err) {

                        dispatch(projectEditResolved(null, new Error()));
                    }
                };

                Server.projectEdit(args);
            }
        },

        projectComplete: function (project) {

            return function (dispatch) {

                const args = {
                    project: project,
                    success: function (result) {

                        dispatch(projectCompleteResolved(result));
                    },
                    error: function (xhr, status, err) {

                        dispatch(projectCompleteResolved(null, new Error()));
                    }
                };

                Server.projectComplete(args);
            }
        },

        projectShare: function (project, contact) {

            return function (dispatch) {

                const args = {
                    project: project,
                    contact: contact,
                    success: function () {

                        dispatch(projectShareResolved());
                    },
                    error: function (xhr, status, err) {

                        dispatch(projectShareResolved(new Error()));
                    }
                };

                Server.projectShare(args);
            }
        },

        projectActivate: function (project) {

            return function (dispatch) {

                const args = {
                    project: project,
                    success: function (result) {

                        dispatch(projectEditResolved(result.project));
                        result.things.forEach(t => dispatch(thingEditResolved(t)));
                    },
                    error: function (err) {

                        dispatch(projectEditResolved(null, err));
                    }
                };

                Server.projectActivate(args);
            }
        },

        projectDeactivate: function (project) {

            return function (dispatch) {

                const args = {
                    project: project,
                    success: function (result) {

                        dispatch(projectEditResolved(result.project));
                        result.things.forEach(t => dispatch(thingEditResolved(t)));
                    },
                    error: function (err) {

                        dispatch(projectEditResolved(null, err));
                    }
                };

                Server.projectDeactivate(args);
            }
        },

        // Context

        contextListLoad: function() {
            
            return function(dispatch) {
                
                Server.contexts({
                    deleted: false,
                    success: function (contexts) {
                        dispatch(contextListLoadResolved(contexts));
                    },
                    error: function (xhr, status, err) {
                        dispatch(contextListLoadResolved(null, new Error()));
                    }
                });
            }
        },

        contextCreate: function (context) {

            return function (dispatch) {

                const args = {
                    context: context,
                    success: function (savedContext) {

                        dispatch(contextCreateResolved(savedContext));
                        // TODO navigate to new context
                    },
                    error: function (xhr, status, err) {

                        dispatch(contextCreateResolved(null, new Error()));
                    }
                };

                Server.contextCreate(args);
            }
        },

        contextEdit: function (context) {

            return function (dispatch) {

                const args = {
                    context: context,
                    success: function (savedContext) {

                        dispatch(contextEditResolved(savedContext));
                    },
                    error: function (xhr, status, err) {

                        dispatch(contextEditResolved(null, new Error()));
                    }
                };

                Server.contextEdit(args);
            }
        },

        contextDelete: function (context) {

            return function (dispatch) {

                const args = {
                    context: context,
                    success: function (result) {

                        dispatch(contextDeleteResolved(result));
                    },
                    error: function (xhr, status, err) {

                        dispatch(contextDeleteResolved(null, new Error()));
                    }
                };

                Server.contextDelete(args);
            }
        },

        contextShare: function (context, contact) {

            return function (dispatch) {

                const args = {
                    context: context,
                    contact: contact,
                    success: function () {

                        dispatch(contextShareResolved());
                    },
                    error: function (xhr, status, err) {

                        dispatch(contextShareResolved(new Error()));
                    }
                };

                Server.contextShare(args);
            }
        },

        contextJoin: function (context, parent) {

            return function (dispatch) {

                const args = {
                    context: context,
                    parent: parent,
                    success: function (contexts) {

                        dispatch(contextJoinResolved(contexts));
                    },
                    error: function (xhr, status, err) {

                        dispatch(contextJoinResolved(new Error()));
                    }
                };

                Server.contextJoin(args);
            }
        },

        contextUnjoin: function (context) {

            return function (dispatch) {

                const args = {
                    context: context,
                    success: function (updatedContext) {

                        dispatch(contextUnjoinResolved(updatedContext));
                    },
                    error: function (xhr, status, err) {

                        dispatch(contextUnjoinResolved(new Error()));
                    }
                };

                Server.contextUnjoin(args);
            }
        },

        // Thing

        thingListLoad: function () {

            return function(dispatch) {

                Server.things({
                    success: function (things) {
                        dispatch(thingListLoadResolved(things));
                    },
                    error: function (xhr, status, err) {
                        dispatch(thingListLoadResolved(null, new Error()));
                    }
                });
            };
        },

        thingListPlanned: function (things) {

            return {
                type: Actions.thingListPlanned,
                payload: {
                    things: things
                }
            };
        },

        thingCompletedLoading: function () {

            return {
                type: Actions.thingCompletedLoading
            };
        },

        thingCompletedLoad: function (pageNumber) {

            return function (dispatch) {

                Server.completed({

                    pageNumber,

                    success: function (response) {

                        dispatch(thingCompletedLoadResolved({
                            things: response.payload.things,
                            pageNumber,
                            pageCount: response.meta.pageCount,
                            thingCount: response.meta.thingCount
                        }));

                    },
                    error: function (xhr, status, err) {

                        dispatch(thingCompletedLoadResolved(null, new Error()));
                    }
                });
            }
        },

        thingDeletedLoading: function () {

            return {
                type: Actions.thingDeletedLoading
            };
        },

        thingDeletedLoad: function (pageNumber) {
          
            return function(dispatch) {
                
                Server.deleted({

                    pageNumber,

                    success: function (response) {

                        dispatch(thingDeletedLoadResolved({
                            things: response.payload.things,
                            pageNumber,
                            pageCount: response.meta.pageCount,
                            thingCount: response.meta.thingCount
                        }));

                    },
                    error: function(xhr, status, err) {
                        
                        dispatch(thingDeletedLoadResolved(null, new Error()));
                    }
                });
            }
        },

        thingAdd: function(thing) {
            
            return function(dispatch) {

                const args = {
                    thing: thing,
                    success: function (savedThing) {

                        dispatch(thingAddResolved(savedThing));
                    },
                    error: function (xhr, status, err) {

                        dispatch(thingAddResolved(null, new Error()));
                    }
                };

                Server.thingCreate(args);
            }
        },

        thingComplete: function (thing) {

            return function (dispatch) {

                const args = {
                    thing: thing,
                    success: function (updatedThing) {

                        dispatch(thingCompleteResolved(updatedThing));
                    },
                    error: function (xhr, status, err) {

                        dispatch(thingCompleteResolved(undefined, new Error()));
                    }
                };

                Server.thingComplete(args);
            }
        },

        thingUncomplete: function(thing) {
            
            return function(dispatch) {

                const args = {
                    thing: thing,
                    success: function (updatedThing) {

                        dispatch(thingUncompleteResolved(updatedThing));
                    },
                    error: function (xhr, status, err) {

                        dispatch(thingUncompleteResolved(null, new Error()));
                    }
                };

                Server.thingUncomplete(args);
            }
        },

        thingDelete: function(thing) {
          
            return function(dispatch) {

                const args = {
                    thing: thing,
                    success: function (deletedThing) {

                        dispatch(thingDeleteResolved(deletedThing));
                    },
                    error: function (xhr, status, err) {

                        dispatch(thingDeleteResolved(null, new Error()));
                    }
                };

                Server.thingDelete(args);
            }
        },

        thingUndelete: function(thing) {
            
            return function(dispatch) {

                const args = {
                    thing: thing,
                    success: function (updatedThing) {

                        dispatch(thingUndeleteResolved(updatedThing));
                    },
                    error: function (xhr, status, err) {

                        dispatch(thingUndeleteResolved(null, new Error()));
                    }
                };

                Server.thingUndelete(args);
            }
        },

        thingEdit: function(thing) {
            
            return function(dispatch) {

                const args = {

                    thing: thing,
                    success: function (payload) {

                        payload.thing && dispatch(thingEditResolved(payload.thing));
                        payload.thingPlanner && dispatch(thingPlannerCreateResolved(payload.thingPlanner));
                    },
                    error: function (err) {

                        dispatch(thingEditResolved(null, err));
                    }
                };

                Server.thingEdit(args);
            }
        },

        // ThingPlanner

        thingPlannerListLoad: function () {

            return function(dispatch) {
                
                Server.thingPlanners({
                    success: function (planners) {
                        dispatch(thingPlannerListLoadResolved(planners));
                    },
                    error: function (xhr, status, err) {
                        dispatch(thingPlannerListLoadResolved(null, new Error()));
                    }
                });
            }
        },

        thingPlannerComplete: function (planner) {

            return function(dispatch) {

                const args = {
                    thingPlanner: planner,
                    success: function (payload) {

                        dispatch(thingPlannerCompleteResolved(payload.thingPlanner));
                        payload.things && payload.things.each(t => dispatch(thingEditResolved(t)));
                    },
                    error: function (err) {

                        dispatch(thingPlannerCompleteResolved(null, err));
                    }
                };

                Server.thingPlannerComplete(args);
            }
        },

        thingPlannerUncomplete: function (thingPlanner) {

            return function (dispatch) {

                const args = {
                    thingPlanner,
                    success: function (payload) {

                        dispatch(thingPlannerUncompleteResolved(payload.thingPlanner));
                        // TODO do smth with payload.project
                    },
                    error: function (err) {

                        dispatch(thingPlannerUncompleteResolved(null, err));
                    }
                };

                Server.thingPlannerUncomplete(args);
            }
        },

        thingPlannerEdit: function (thingPlanner) {

            return function (dispatch) {

                const args = {

                    thingPlanner,
                    success: function (editableThingPlanner) {

                        dispatch(thingPlannerEditResolved(editableThingPlanner));
                    },
                    error: function (err) {

                        dispatch(thingPlannerEditResolved(null, err));
                    }
                };

                Server.thingPlannerEdit(args);
            }
        },

        thingPlannerShowAll: function () {

            return {
                type: Actions.thingPlannerShowAll
            };
        },

        thingPlannerHideAll: function () {

            return {
                type: Actions.thingPlannerHideAll
            };
        },

        thingPlannerShowProject: function (project) {

            return {
                type: Actions.thingPlannerShowProject,
                payload: {
                    project: project
                }
            };
        },

        thingPlannerShowContext: function (context) {

            return {
                type: Actions.thingPlannerShowContext,
                payload: {
                    context: context
                }
            };
        },

        
    }
}();
