import {Glyphicon} from "../../../common/glyphs";
import {DeleteContextBtn} from "./DeleteContextBtn";
import {UnjoinContextBtn} from "./UnjoinContextBtn";
import {useSelector} from "react-redux";
import {selectContextsByParentId} from "../slices/contextApiSlice";
import {Link} from "react-router-dom";
import {JoinContextBtn} from "./JoinContextBtn";
import {EditContextBtn} from "./EditContextBtn";
import {ShareContextBtn} from "./ShareContextBtn";

export const ContextToolbar = ({ context }) => {

    const innerContexts = useSelector(state => selectContextsByParentId(state, context.id));

    const inner = innerContexts.length > 0
        ?   <div className="btn-group dropdown">
                <button className={'btn btn-default dropdown-toggle ' + Glyphicon.menuHamburger} type="button" aria-expanded="true" id="child-dropdown" data-toggle="dropdown" title="Вложенные контексты"></button>
                <ul className="dropdown-menu" aria-labelledby="child-dropdown">
                    {innerContexts.map(c => <li key={c.id}><Link to={'/context/' + c.id} title={c.title}>{c.title}</Link></li>)}
                </ul>
            </div>
        : null;
    
    const joinUnjoinLink = context.parentId
        ? <UnjoinContextBtn context={context} />
        : <JoinContextBtn context={context} />;
    
    return (
        <div className="btn-toolbar">
            <span className="btn toolbar-caption" title={context.title}>
                {context.title}
            </span>
            <EditContextBtn context={context} className="me-1" />
            {/* TODO */}
            {/*<DeleteContextBtn context={context} className="me-1" />*/}
            {/*<ShareContextBtn context={context} className="me-1" />*/}
            {/*{joinUnjoinLink}*/}
            {inner}
        </div>
    );
}