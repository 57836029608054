import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ThingsList} from "./ThingsList";

export const ThingsGroup = ( { group, icon, things, sortBy, hideLabels, onComplete }) => {

    return (
        <>
            <div className="d-flex flex-row align-items-center text-muted mt-4">
                {icon ? <FontAwesomeIcon icon={icon} className="me-1" /> : null}
                <span className="me-auto fw-bold">{group}</span>
            </div>
            <ThingsList things={things} sortBy={sortBy} hideLabels={hideLabels} onComplete={onComplete}/>
        </>
    );
}