import {Glyphicon} from "../../common/glyphs";
import {SchedulerBtn} from "./SchedulerBtn";
import {LeftPanelGroup} from "../left-panel/LeftPanelGroup";

export const SchedulerGroup = ({ group, ...rest }) => {

    let items = group.items.map(scheduler => <SchedulerBtn key={scheduler.type} scheduler={scheduler} {...rest} />);

    return (
        <>
            <LeftPanelGroup icon={Glyphicon[group.icon]} title={group.title} />
            {items}
        </>
    );
};