import {Glyphicon} from "../../../common/glyphs";
import {ToolForm} from "../../shared/ToolForm";
import {useUpdateProjectMutation} from "../slices/projectApiSlice";

export const DeactivateProjectBtn = ({ project, ...rest }) => {
    
    const [ save ] = useUpdateProjectMutation();
    
    const handleSubmit = async (project) => {
        
        const updatedProject = {
            ...project,
            isActive: false
        }
        
        await save(updatedProject);
    }
    
    return <ToolForm entity={project} onSubmit={handleSubmit} icon={Glyphicon.pause} title="Сделать проект неактивным" {...rest} />;
}