import {Glyphicon} from "../../../common/glyphs";
import {ToolForm} from "../../shared/ToolForm";
import {useDeleteThingMutation} from "../slices/thingApiSlice";

export const DeleteThingBtn = ( {thing, hard=false, onDelete, ...rest }) => {
    
    const [save] = useDeleteThingMutation();
    
    const handleSubmit = async (thing) => {

        await save({
            id: thing.id,
            hard
        })
        
        onDelete && onDelete(thing);
    };

    return <ToolForm entity={thing} onSubmit={handleSubmit} icon={Glyphicon.delete} title="Удалить" {...rest}/>;
}