import {useSelector} from "react-redux";
import {ProjectBtn} from "./ProjectBtn";
import {selectActiveProjectsIds, selectProjectsIds} from "./slices/projectApiSlice";
import {selectHideInactiveProjects} from "../common/commonSlice";

export const ProjectList = ({...rest}) => {

    const hideInactiveProjects = useSelector(state => selectHideInactiveProjects(state));
    const projectsIds = useSelector(state => selectProjectsIds(state));
    const activeProjectsIds = useSelector(state => selectActiveProjectsIds(state));

    if (!projectsIds) return null;
    
    const visibleProjectsIds = hideInactiveProjects
        ? activeProjectsIds
        : projectsIds;
    
    return visibleProjectsIds?.map(id => <ProjectBtn key={id} projectId={id} {...rest} />);
}