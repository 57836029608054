import {apiSlice} from "../../api/apiSlice";
import {Tags} from "../../api/constants";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";

const projectsAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.title.localeCompare(b.title)
});

const initialState = projectsAdapter.getInitialState();

const projectApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        
        getProjects: builder.query({
            query: _ => ({
                url: "/project",
                method: "GET"
            }),
            transformResponse: data => projectsAdapter.setAll(initialState, data),
            providesTags: [Tags.projects]
        }),
        
        createProject: builder.mutation({
            query: project => ({
                url: "/project",
                method: "POST",
                body: project
            }),
            invalidatesTags: [Tags.projects]
        }),
        
        updateProject: builder.mutation({
            query: project => ({
                url: "/project",
                method: "PUT",
                body: project
            }),
            invalidatesTags: [Tags.projects]
        })
    })
})

export const {
    useGetProjectsQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation
} = projectApiSlice;

// selectors

const selectProjectsResult = projectApiSlice.endpoints.getProjects.select();

const selectProjectsData = createSelector(
    selectProjectsResult,
    projectsResult => projectsResult.data  
);

export const {
    selectIds: selectProjectsIds,
    selectAll: selectAllProjects,
    selectById: selectProjectById,
} = projectsAdapter.getSelectors(state => selectProjectsData(state) ?? initialState);

export const selectActiveProjectsIds = createSelector(
    selectProjectsData,
    data => data?.ids.filter(id => data.entities[id].isActive)
);