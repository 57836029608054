import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {selectedChanged} from "../common/commonSlice";
import {GroupThingsBy} from "../../common/groupping";
import {SortThingsBy} from "../../common/sortings";
import {selectContextById} from "./slices/contextApiSlice";
import {ThingCreate} from "../things/ThingCreate";
import {Things} from "../things/Things";
import {ContextToolbar} from "./tools/ContextToolbar";
import {selectThingsByContext} from "../things/slices/thingApiSlice";

export const ThingsByContext = () => {
    
    const { id: contextId } = useParams();
    const context = useSelector(state => selectContextById(state, contextId));
    const dispatch = useDispatch();
    
    useEffect(() => {

        dispatch(selectedChanged({ context: context }));
    }, [ context?.id ]);

    const things = useSelector(state => selectThingsByContext(state, contextId));

    if (!context) return null;

    return (
        <div className="col-xs-12">
            <ThingCreate />
            <ContextToolbar context={context} />
            <Things things={things}
                    hideLabels={{context: true, scheduler: true}}
                    groupBy={GroupThingsBy.scheduler}
                    sortBy={SortThingsBy.createdDesc} />
        </div>
    );
}