import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectThingById, useGetThingsQuerySubscription} from "./slices/thingApiSlice";
import {ThingToolbar} from "./tools/ThingToolbar";
import {selectSelected} from "../common/commonSlice";
import {Scheduler} from "../schedulers/schedulers";
import {splitBy} from "../../common/split-by";
import {guid} from "../../common/guid";
import {RegularExpression} from "../../common/regular-expressions";
import {ThingLabels} from "./ThingLabels";

export const ThingDetails = () => {

    useGetThingsQuerySubscription();
    
    const { id: thingId } = useParams();
    
    const thing = useSelector(state => selectThingById(state, thingId));
    const selected = useSelector(state => selectSelected(state));
    
    const navigate = useNavigate();

    if (!thing) return null;

    const navigateToSelected = () => {

        const path =
            selected.scheduler
                ? selected.scheduler.type === Scheduler.today ? "/" : `/${selected.scheduler.type}` 
            : selected.context
                ? `/context/${selected.context.id}`
            : selected.project
                ? `/project/${selected.project.id}`
            : null;
        
        if (!path) return;

        navigate(path);
    }

    const title = RegularExpression.url.test(thing.title) ? splitBy.urls(thing.title) : thing.title;

    let description = null;

    if (thing.description) {

        const descrP = splitBy.newLine(thing.description).map((s, i) => {

            if (!s) return <p key={guid()}></p>;

            const descrParts = splitBy.urls(s);

            return <p key={'descr-line-' + i}>{descrParts}</p>;
        });
        
        description = <div className="form-group">
                          {descrP}
                      </div>;
    }

    return (
        <div className="col-xs-12">
            <ThingToolbar thing={thing} onComplete={navigateToSelected} onDelete={navigateToSelected}/>
            <div className="mb-3"><ThingLabels thing={thing} hideLabels={{description:true}} /></div>
            <div className="mb-2 pb-1 thing-title">
                {title}
            </div>
            {description}
        </div>
    );
}


// var ThingDetails = React.createClass({
//
//     getThingId: function () { return this.props.match.params.id },
//
//     navigateToReferer: function (thing) {
//
//         var returnUrl = this.props.location.state && this.props.location.state.referer
//             ? this.props.location.state.referer.pathname +
//                 this.props.location.state.referer.search +
//                 this.props.location.state.referer.hash
//             : '/' + Scheduler.getUrlPath(thing.scheduler);
//         this.props.history.push(returnUrl);
//     },
//
//     handleSelectedChanged: function () {
//        
//         if (this.props.selected &&
//         (this.props.selected.scheduler ||
//             this.props.selected.context ||
//             this.props.selected.project ||
//             this.props.selected.filter)) return;
//
//         var thing = this.props.things.items.find(function (t) { return t.id === this.getThingId() }.bind(this));
//
//         if (!thing) return;
//
//         this.props.onSelectedChanged &&
//             this.props.onSelectedChanged({
//                 scheduler: {
//                     type: thing.scheduler,
//                     title: Scheduler.getTitle(thing.scheduler),
//                     icon: Scheduler.getIconName(thing.scheduler)
//                 }
//             });
//     },
//
//     handleDelete: function(thing) {
//
//         this.props.onDelete && this.props.onDelete(thing);
//         this.navigateToReferer(thing);
//     },
//
//     handleUndelete: function (thing) {
//
//         this.props.onUndelete && this.props.onUndelete(thing);
//         this.navigateToReferer(thing);
//     },
//
//     handleComplete: function (thing) {
//
//         this.props.onComplete && this.props.onComplete(thing);
//         this.navigateToReferer(thing);
//     },
//
//     handleUncomplete: function (thing) {
//
//         this.props.onUncomplete && this.props.onUncomplete(thing);
//         this.navigateToReferer(thing);
//     },
//
//     componentDidMount: function () {
//
//         this.handleSelectedChanged();
//     },
//
//     componentDidUpdate: function() {
//
//         this.handleSelectedChanged();
//     },
//
//     render: function () {
//        
//         var thing = this.props.things.items.find(function (t) { return t.id === this.getThingId() }.bind(this));
//
//         if (!thing) return null;
//
//         var completeTool = thing.done
//             ? <ToolForm entity={thing} onSubmit={this.handleUncomplete} glyphClass={Glyphicon.uncomplete} title="Не готово" />
//             : <ToolForm entity={thing} onSubmit={this.handleComplete} glyphClass={Glyphicon.complete} title="Готово" />;
//        
//         var deleteTool = thing.deleted
//             ? <ToolForm entity={thing} onSubmit={this.handleUndelete} glyphClass={Glyphicon.undelete} title="Восстановить" />
//             : <ToolForm entity={thing} onSubmit={this.handleDelete} glyphClass={Glyphicon.delete} title="Удалить" />;
//
//         var title = Common.testUrl(thing.title) ? Common.splitByUrls(thing.title) : thing.title;
//
//         var description = null;
//
//         if (thing.description) {
//
//
//
//             var descrP = Common.splitByNewLine(thing.description).map((s, i) => {
//
//                 if (!s) return <p key={guid()}></p>;
//
//                 var descrParts = Common.splitByUrls(s);
//                
//                 return <p key={'descr-line-' + i}>{descrParts}</p>;
//             });
//             description = <div className="form-group">
//                               {descrP}
//                           </div>;
//         }
//
//         var deleteDate = new Date(thing.deleteDate);
//         var doneDate = new Date(thing.doneDate);
//         var startDate = new Date(thing.start);
//         var deleteDateLabel;
//         var doneDateLabel;
//         var startDateLabel;
//         var contextLabel;
//         var projectLabel;
//
//         if (thing.deleted) deleteDateLabel = <ThingLabel glyphClass={Glyphicon.trash} text={formatDate(deleteDate) } />;
//         if (thing.done) doneDateLabel = <ThingLabel glyphClass={Glyphicon.complete} text={formatDate(doneDate) }/>;
//        
//         if (thing.start) {
//
//             var today = new Date();
//             var todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
//             var colorName = !thing.done && new Date(thing.start) < todayStart ? LabelColor.danger : LabelColor.def;
//             startDateLabel = <ThingLabel color={colorName} glyphClass={Glyphicon.calendar} text={formatDate(startDate)}/>;
//         }
//
//         if (thing.contextId) {
//            
//             var context = this.props.contexts.find(c => c.id === thing.contextId);
//
//             if (context) {
//
//                 contextLabel = <ThingLabel glyphClass={Glyphicon.mapMarker} text={context.title}/>;
//             }
//         }
//
//         if (thing.projectId) {
//
//             var project = this.props.projects.byId[thing.projectId];
//
//             if (project) {
//
//                 projectLabel = <ThingLabel glyphClass={Glyphicon.briefcase} text={project.title}/>;
//             }
//         }
//
//         return <div className="col-xs-12">
//                     <div className="btn-toolbar">
//                         <div className="btn-group">
//                             {completeTool}
//                         </div>
//                         <div className="btn-group">
//                             {deleteTool}
//                         </div>
//                         <div className="btn-group">
//                             <ToolLink to={{pathname: '/thing/edit/' + this.getThingId(), state: this.props.location.state }} glyphClass={Glyphicon.edit} title="Редактировать" />
//                         </div>
//                     </div>
//                     <div className="form-group">{deleteDateLabel} {doneDateLabel} {startDateLabel} {contextLabel} {projectLabel}{/* В одну строку через пробел, чтобы метки не «слипались». */}</div>
//                     <div className="form-group thing-title">
//                         <p>{title}</p>
//                     </div>
//                     {description}
//                 </div>;
//     }
// });
//
// var mapThingDetailsStateToProps = function (state) {
//
//     return {
//
//         selected: state.common.selected,
//         things: state.things,
//         contexts: state.contexts,
//         projects: state.projects
//     }
// };
//
// var mapThingDetailsDispatchToProps = function (dispatch) {
//    
//     return {
//
//         onSelectedChanged: function(selected) {
//
//             dispatch(ActionCreator.commonSelectedChange(selected));
//         },
//
//         onComplete: function (thing) {
//
//             dispatch(ActionCreator.thingComplete(thing));
//         },
//
//         onUncomplete: function (thing) {
//
//             dispatch(ActionCreator.thingUncomplete(thing));
//         },
//
//         onDelete: function (thing) {
//
//             dispatch(ActionCreator.thingDelete(thing));
//         },
//
//         onUndelete: function (thing) {
//
//             dispatch(ActionCreator.thingUndelete(thing));
//         }
//     }
// }
//
// ThingDetails = ReactRedux.connect(mapThingDetailsStateToProps, mapThingDetailsDispatchToProps)(ThingDetails);