import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ToolForm = ({ entity, onSubmit, icon, title, ...rest }) => {

    const handleSubmit = (event) => {

        event.preventDefault();
        onSubmit && onSubmit(entity);
    };

    return (
        <form action="#" onSubmit={handleSubmit} {...rest}>
            <button className="btn btn-outline-secondary" type="submit" title={title}>
                <FontAwesomeIcon icon={icon} />
            </button>
        </form>
    );
}