import {useParams} from "react-router-dom";
import {useGetThingsQuery} from "./slices/thingApiSlice";
import {ThingEditor} from "./ThingEditor";

export const ThingEdit = () => {

    const { id: thingId } = useParams();
    const { isLoading } = useGetThingsQuery();
    
    return isLoading 
        ? <span className="bg-secondary-subtle form-control">Загрузка</span>
        : <ThingEditor id={thingId}/>;
}