import {Link} from "react-router-dom";

export const LeftPanelBtn = ({to, active, title, children, ...rest}) => {

    const activeClass =  active ? "btn-outline-primary active" : "btn-outline-secondary";
    
    return (
        <li role="presentation" className="nav-item" {...rest}>
            <Link to={to} className={`btn ${activeClass} py-2 mt-1 d-flex`} title={title}>
                {children}
            </Link>
        </li>
    );
}