import {By, groupBy} from "../../common/groupping";
import {ThingsGroup} from "./ThingsGroup";
import {useSelector} from "react-redux";
import {selectContextsData} from "../contexts/slices/contextApiSlice";
import {Glyphicon} from "../../common/glyphs";

const groupByImpl  = groupBy;

export const ThingsGroupedByContext = ({things, hideLabels, onComplete}) => {

    const contexts = useSelector(state => selectContextsData(state));
    
    const groups = groupByImpl(things, thing => contexts.entities[By.context(thing)]?.title ?? "");
    const sortedGroupNames = Array.from(groups.keys()).sort();
    
    return sortedGroupNames?.map(groupName =>
        <ThingsGroup key={groupName} group={groupName} icon={groupName ? Glyphicon.mapMarker : null}
                     things={groups.get(groupName)} hideLabels={hideLabels} onComplete={onComplete}/>
    );
}