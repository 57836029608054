import {Scheduler} from "../schedulers/schedulers";
import {By, groupBy, GroupThingsBy} from "../../common/groupping";
import {ThingsGroup} from "./ThingsGroup";

const groupByImpl  = groupBy;

export const ThingsGroupedByScheduler = ( { things, hideLabels, onComplete }) => {

    const groups = groupByImpl(things, By.scheduler);
    const sortedGroupNames = Array.from(groups.keys()).sort(Scheduler.compare);
    
    return sortedGroupNames?.map(groupName => <ThingsGroup key={groupName} group={Scheduler.getTitle(groupName)} icon={Scheduler.getIcon(groupName)} things={groups.get(groupName)} hideLabels={hideLabels} onComplete={onComplete} />);
}

// TODO implement and remove
// export class Things extends Component{
//
//     render() {
//        
//         if (this.props.things && this.props.things.length === 0 && (!this.props.thingPlanners || this.props.thingPlanners.length === 0)) {
//
//             var noThings = <h4 className="well well-sm">Записей нет</h4>;
//         }
//
//         if (this.props.things && this.props.things.length !== 0) {
//
//             const groupBy = this.props.groupBy || GroupThingsBy.nothing;
//             const sortBy = this.props.sortBy || SortThingsBy.created;
//
//             switch(groupBy) {
//
//                 case GroupThingsBy.nothing:
//
//                     var things = this.props.things;
//                     things.sort(SortThingsBy.getSortFunc(sortBy));
//
//                     things = things.map(function (thing) {
//
//                         return <Thing key={thing.id}
//                             thing={thing}
//                             onClick={this.props.onThingClick}
//                             getContext={this.props.getContext}
//                             getProject={this.props.getProject}
//                         />;
//
//                     }.bind(this));
//                     break;
//
//                 case GroupThingsBy.project:
//
//                     const projects = [];
//                     const thingsByProjects = {};
//
//                     this.props.things.forEach(function(t){
//
//                         if (!thingsByProjects.hasOwnProperty(t.projectId)) {
//
//                             thingsByProjects[t.projectId] = [];
//                             projects.push(this.props.getProject(t.projectId) || { id: null, title: ''}); // В массиве не д.б. undefined, иначе sort() не вызывает функцию сравнения.
//                         }
//                         thingsByProjects[t.projectId].push(t);
//                     }.bind(this));
//
//                     projects.sort(function(a, b){
//                        
//                         if (!a.id && !b.id) return 0;
//                         if (!a.id) return -1;
//                         if (!b.id) return 1;
//                         if (a.title > b.title) return -1;
//                         if (a.title < b.title) return 1;
//                         return 0;
//                     });
//
//                     things = [];
//
//                     projects.forEach(function(p){
//                    
//                         if (p.id) {
//
//                             things.push(<h5 key={p.id} className="text-muted"><span className={"section-icon " + Glyphicon.briefcase}></span> {p.title}</h5>);
//                         }
//
//                         thingsByProjects[p.id].sort(SortThingsBy.getSortFunc(sortBy));
//
//                         thingsByProjects[p.id].forEach(function(t) {
//                        
//                             things.push(<Thing key={t.id}
//                                             thing={t}
//                                             onClick={this.props.onThingClick}
//                                             hideLabels={Object.assign({project: true}, this.props.hideLabels)}
//                                             getContext={this.props.getContext}
//                                             getProject={this.props.getProject}
//                                             />);
//                         }.bind(this));
//                     }.bind(this));
//                     break;
//
//                 case GroupThingsBy.context:
//
//                     const contexts = [];
//                     const thingsByContexts = {};
//
//                     this.props.things.forEach(function(t){
//
//                         if (!thingsByContexts.hasOwnProperty(t.contextId)) {
//
//                             thingsByContexts[t.contextId] = [];
//                             contexts.push(this.props.getContext(t.contextId) || { id: null, title: ''}); // В массиве не д.б. undefined, иначе sort() не вызывает функцию сравнения.
//                         }
//                         thingsByContexts[t.contextId].push(t);
//                     }.bind(this));
//
//                     contexts.sort(function(a, b){
//                        
//                         if (!a.id && !b.id) return 0;
//                         if (!a.id) return -1;
//                         if (!b.id) return 1;
//                         if (a.title > b.title) return -1;
//                         if (a.title < b.title) return 1;
//                         return 0;
//                     });
//
//                     things = [];
//
//                     contexts.forEach(function(c){
//                    
//                         if (c.id) {
//
//                             things.push(<h5 key={c.id} className="text-muted"><span className={"section-icon " + Glyphicon.mapMarker}></span> {c.title}</h5>);
//                         }
//
//                         thingsByContexts[c.id].sort(SortThingsBy.getSortFunc(sortBy));
//
//                         thingsByContexts[c.id].forEach(function(t) {
//                        
//                             things.push(<Thing  key={t.id}
//                                                 thing={t}
//                                                 onClick={this.props.onThingClick}
//                                                 hideLabels={ Object.assign({context: true}, this.props.hideLabels)}
//                                                 getContext={this.props.getContext}
//                                                 getProject={this.props.getProject}
//                                             />);
//                         }.bind(this));
//                     }.bind(this));
//                     break;
//
//                 case GroupThingsBy.scheduler:
//
//                     const schedulers = [];
//                     const thingsBySchedulers = {};
//
//                     this.props.things.forEach(function(t){
//
//                         if (!thingsBySchedulers.hasOwnProperty(t.scheduler)) {
//
//                             thingsBySchedulers[t.scheduler] = [];
//                             schedulers.push(t.scheduler);
//                         }
//                         thingsBySchedulers[t.scheduler].push(t);
//                     }.bind(this));
//
//                     schedulers.sort(Scheduler.compare);
//
//                     things = [];
//
//                     schedulers.forEach(function (sch) {
//
//                         const iconClassName = "glyphicon section-icon glyphicon-" + Scheduler.getIconName(sch);
//                         things.push(<h5 key={sch} className="text-muted"><span className={iconClassName}></span> {
//                             Scheduler.getTitle(sch)}</h5>);
//
//                         thingsBySchedulers[sch].sort(SortThingsBy.getSortFunc(sortBy));
//
//                         thingsBySchedulers[sch].forEach(function (t) {
//                        
//                             things.push(<Thing  key={t.id}
//                                                 thing={t}
//                                                 onClick={this.props.onThingClick}
//                                                 hideLabels={ Object.assign({context: true}, this.props.hideLabels)}
//                                                 getContext={this.props.getContext}
//                                                 getProject={this.props.getProject}
//                                             />);
//                         }.bind(this));
//                     }.bind(this));
//                     break;
//
//                 case GroupThingsBy.period:
//
//                     const periods = [];
//                     const thingsByPeriods = {};
//
//                     this.props.things.forEach(function(t) {
//
//                         const period = ScheduledPeriod.get(new Date(t.start));
//
//                         if (!thingsByPeriods.hasOwnProperty(period)) {
//
//                             thingsByPeriods[period] = [];
//                             periods.push(period);
//                         }
//                         thingsByPeriods[period].push(t);
//
//                     }.bind(this));
//
//                     periods.sort((a,b) => ScheduledPeriod.getOrder(a) - ScheduledPeriod.getOrder(b));
//
//                     things = [];
//
//                     periods.forEach(function (p) {
//                    
//                         things.push(<h5 key={p} className="text-muted">{ScheduledPeriod.getTitle(p)}</h5>);
//                         thingsByPeriods[p].sort(SortThingsBy.getSortFunc(sortBy));
//                         thingsByPeriods[p].forEach(function (t) {
//                        
//                             things.push(<Thing  key={t.id}
//                                                 thing={t}
//                                                 onClick={this.props.onThingClick}
//                                                 hideLabels={this.props.hideLabels}
//                                                 getContext={this.props.getContext}
//                                                 getProject={this.props.getProject}
//                                             />);
//                         }.bind(this));
//                     }.bind(this));
//
//                     break;
//             }
//         }
//
//         return  <div>
//                     <div id="things">
//                         {noThings}
//                         {/*TODO transition*/}
//                         {/*<React.addons.CSSTransitionGroup transitionName="fade" transitionEnterTimeout={500} transitionLeave={false}>*/}
//                             {things}
//                         {/*</React.addons.CSSTransitionGroup>*/}
//                     </div>
//                     <ThingPlanners onThingPlannerClick={this.props.onThingPlannerClick} />
//                 </div>;
//     }
// }
//
// function mapStateToProps(state) {
//
//     return {
//
//         getContext: function (id) {
//
//             for (let i = 0; i < state.contexts.length; i++) {
//
//                 if (state.contexts[i].id === id) return state.contexts[i];
//             }
//
//             return undefined;
//         },
//
//         getProject: function (id) {
//            
//             return state.projects.byId[id];
//         }
//     }
// }
//
// Things = connect(mapStateToProps, null)(Things);