import {Actions} from "../actions";

export const contactReducer = function (state, action) {

    if (state === undefined || state === null) {

        return [];
    };

    switch (action.type) {

        case Actions.contactListLoad:

            return action.payload && action.payload.contacts
                ? state.concat(action.payload.contacts)
                : state;
    }

    return state;
}
