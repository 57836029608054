import {useDispatch, useSelector} from "react-redux";
import {descriptionChanged, titleChanged, resetNewProject} from "./slices/newProjectSlice";
import {useCreateProjectMutation} from "./slices/projectApiSlice";
import {useNavigate} from "react-router-dom";

export const ProjectCreate = () => {
    
    const newProject = useSelector(state => state.newProject);
    const [save] = useCreateProjectMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleTitleChanged = (event) => {
        
        event.preventDefault();
        dispatch(titleChanged(event.target.value));
    }
    
    const handleDescriptionChanged = (event) => {
        
        event.preventDefault();
        dispatch(descriptionChanged(event.target.value));
    }
    
    const handleSubmit = async (event) => {
        
        event.preventDefault();
        dispatch(resetNewProject());
        const result = await save(newProject).unwrap();
        
        navigate(`/project/${result.id}`);
    }
    
    return (
        <div className="col-xs-12">
            <form action="#" method="POST" onSubmit={handleSubmit}>
                 <div className="form-floating mb-2">
                     <input id="title" className="form-control" value={newProject.title} onChange={handleTitleChanged} placeholder="Заголовок"/>
                     <label htmlFor="title">Заголовок</label>
                 </div>
                 <div className="form-floating mb-2">
                     <textarea id="description" className="form-control textarea-rows-5" value={newProject.description} onChange={handleDescriptionChanged} placeholder="Описание"></textarea>
                     <label htmlFor="description">Описание</label>
                 </div>
                 <button className="btn btn-outline-secondary" type="submit">Сохранить</button>
            </form>
        </div>
    );
}