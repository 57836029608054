import {useDispatch, useSelector} from "react-redux";
import {Scheduler, Schedulers} from "../schedulers/schedulers";
import {useCreateThingMutation} from "./slices/thingApiSlice";
import {contextChanged, projectChanged, schedulerChanged, titleChanged} from "./slices/newThingSlice";
import {today, tomorrow} from "../../common/date-time";
import {useNavigate} from "react-router-dom";
import {useEffect, useRef} from "react";
import {selectAllContexts} from "../contexts/slices/contextApiSlice";
import {CreateThingDropdown} from "./CreateThingDropdown";
import {selectAllProjects} from "../projects/slices/projectApiSlice";
import {Glyphicon} from "../../common/glyphs";
import {ThingLabel} from "./ThingLabel";

export const ThingCreate = () => {
    
    const newThing = useSelector(state => state.newThing);
    const contexts = useSelector(state => selectAllContexts(state));
    const projects = useSelector(state => selectAllProjects(state));
    const selected = useSelector(state => state.common.selected);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ save, { isLoading } ] = useCreateThingMutation();
    const inputRef = useRef(null);
    
    useEffect(() => {

        inputRef.current?.focus();
        
        // reset new thing
        dispatch(contextChanged(selected.context));
        dispatch(projectChanged(selected.project));
        dispatch(schedulerChanged(selected.scheduler?.id === Scheduler.scheduled ? Scheduler.create(Scheduler.inbox) : selected.scheduler));
    }, [selected]);
    
    const handleTitleChange = (event) => {

        dispatch(titleChanged(event.target.value));
    }

    const handleSchedulerChanged = (scheduler) => {
        
        dispatch(schedulerChanged(scheduler))
    }
    
    const handleContextChanged = (context) => {
        
        dispatch(contextChanged(context));
    }
    
    const handleProjectChanged = (project) => {
        
        dispatch(projectChanged(project));
    }
    
    const handleSubmit = async (event) => {

        event.preventDefault();

        let schedulerType = newThing.scheduler.id;
        let start = null;
        let navigateToInbox = selected.scheduler && selected.scheduler.id !== newThing.scheduler.id;

        if (newThing.scheduler.id === Scheduler.today) {
            
            schedulerType = Scheduler.scheduled
            start = today();
        }
        else if (newThing.scheduler.id === Scheduler.tomorrow) {

            schedulerType = Scheduler.scheduled
            start = tomorrow();
        }
        
        let thing = {
            title:newThing.title,
            contextId:newThing.context?.id,
            projectId:newThing.project?.id,
            schedulerType: schedulerType,
            start
        };
        
        dispatch(titleChanged(""));
        await save(thing);
        
        navigateToInbox && navigate("/inbox");
    }

    const schedulerIcon = newThing.scheduler.id === Scheduler.inbox ? Glyphicon.inbox : Glyphicon.calendar;
    const schedulersSkipScheduled = Schedulers.filter(scheduler => scheduler.id !== Scheduler.scheduled);
    
    const schedulerBadge =
        selected.scheduler
            ? null
            : <CreateThingDropdown className="me-2" entities={schedulersSkipScheduled} currentId={newThing.scheduler.id} icon={schedulerIcon} allowEmpty={false} onSelect={handleSchedulerChanged}/>;
    
    const contextBadge =
        selected.context && newThing.context
            ? null
            : <CreateThingDropdown className="me-2" text="Контекст" entities={contexts} currentId={newThing.context?.id} icon={Glyphicon.mapMarker} onSelect={handleContextChanged}/>;

    const projectBadge =
        selected.project && newThing.project
            ? null
            : <CreateThingDropdown text="Проект" entities={projects} currentId={newThing.project?.id} icon={Glyphicon.briefcase} onSelect={handleProjectChanged}/>;

    return (
        <form action="#" onSubmit={handleSubmit} className="form-control shadow-sm mb-3">
            <div className="input-group mb-1">
                <input type="text" ref={inputRef} className="form-control" name="Title" placeholder="Новая запись" aria-label="Новая запись" value={newThing.title} onChange={handleTitleChange} onBlur={handleTitleChange} />
                <button className="btn btn-outline-secondary" type="submit">Добавить</button>
            </div>
            {schedulerBadge}
            {contextBadge}
            {projectBadge}
        </form>
    );
}




// export class ThingAdd extends Component{
//     constructor(props) {
//         super(props);
//        
//         this.state = { title: ""}
//        
//         this.handleTitleChange = this.handleTitleChange.bind(this);
//         this.handleSubmit = this.handleSubmit.bind(this);
//     }
//    
//     handleTitleChange(e) {
//
//         this.setState({
//             ...this.state,
//             title: e.target.value
//         });
//     }
//
//     handleSubmit(e) {
//
//         e.preventDefault();
//
//         const newThing = { ...this.state };
//
//         let navigateToInbox = false;
//
//         if (this.props.selected && this.props.selected.scheduler) {
//
//             if (this.props.selected.scheduler.type === Scheduler.scheduled) {
//
//                 newThing.scheduler = Scheduler.inbox;
//                 navigateToInbox = true;
//
//             } else {
//
//                 newThing.scheduler = this.props.selected.scheduler.type;
//             }
//
//         } else {
//
//             newThing.scheduler = Scheduler.inbox;
//         }
//
//         newThing.schedulerType = Scheduler.getIndex(newThing.scheduler);
//
//         if (this.props.selected && this.props.selected.project) newThing.projectId = this.props.selected.project.id;
//         if (this.props.selected && this.props.selected.context) newThing.contextId = this.props.selected.context.id;
//
//         this.props.onSubmit && this.props.onSubmit(newThing);
//         this.setState({ title: '' });
//         navigateToInbox && this.props.history.push({pathname: '/inbox'});
//     }
//
//     render() {
//        
//         return <form method="post" action="#" onSubmit={this.handleSubmit}>
//                     <div className="form-group">
//                         <div className="input-group">
//                             <input className="form-control" type="text" name="Title" placeholder="Новая запись" value={this.state.title} onChange={this.handleTitleChange} onBlur={this.handleTitleChange} />
//                             <span className="input-group-btn">
//                                 <button className="btn btn-default" type="submit">Добавить</button>
//                             </span>
//                         </div>
//                     </div>
//                 </form>;
//     }
// }
//
// function mapStateToProps(state) {
//
//     return {
//
//         selected: state.common.selected
//     }
// }
//
// function mapDispatchToProps(dispatch) {
//
//     return {
//
//         onSubmit: function (thing) {
//
//             dispatch(ActionCreator.thingAdd(thing));
//         }
//     }
// }
//
// //ThingAdd = withRouter(connect(mapThingAddStateToProps, mapThingAddDispatchToProps)(ThingAdd));
// ThingAdd = connect(mapStateToProps, mapDispatchToProps)(ThingAdd);