import {Scheduler} from "./schedulers";
import {selectSelected} from "../common/commonSlice";
import {useSelector} from "react-redux";
import {LeftPanelBtn} from "../left-panel/LeftPanelBtn";

export const SchedulerBtn = ({ scheduler, ...rest }) => {

    const selected = useSelector(state => selectSelected(state));
    const to = '/' + (scheduler.type === Scheduler.today ? '' : scheduler.type);
    
    return (
        <LeftPanelBtn to={to} active={selected.scheduler?.type === scheduler.type} title={scheduler.title} {...rest}>
            {scheduler.title}
        </LeftPanelBtn>
    );
}