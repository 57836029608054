import React from "react";
import { Link } from "react-router-dom";
import {Glyphicon} from "../../common/glyphs";
import {Scheduler} from "../schedulers/schedulers";
import {useSelector} from "react-redux";
import {selectSelected} from "../common/commonSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const CurrentLink = ({ ...rest }) => {

    const selected = useSelector(state => selectSelected(state));

    if (!selected) return null;
    if (!selected.scheduler && !selected.filter && !selected.project && !selected.context) return null;

    let to = '/';
    let title = '';
    let icon = null;

    if (selected.scheduler) {

        to = '/' + (selected.scheduler.type === Scheduler.today ? '' : selected.scheduler.type);
        title = selected.scheduler.title;
        icon = Glyphicon[selected.scheduler.icon];

    } else if (selected.filter) {

        to = '/' + selected.filter.type;
        title = selected.filter.title;
        icon = Glyphicon[selected.filter.icon];

    } else if (selected.project) {

        const project = selected.project;
        to = '/project/' + (project?.id ?? '');
        title = project?.title ?? '';
        icon = Glyphicon.briefcase;

    } else if (selected.context) {

        const context = selected.context;
        to = '/context/' + (context?.id ?? '');
        title = context?.title ?? '';
        icon = Glyphicon.mapMarker;
    }
    
    return (
        <Link to={to} title={title} {...rest}>
            <FontAwesomeIcon icon={icon} className="me-1" />{title}
        </Link>
    );
}