import {createSlice} from "@reduxjs/toolkit";
import {Schedulers} from "../../schedulers/schedulers";

const defaultScheduler = Schedulers[0];

const initialState = {
    title: "",
    scheduler: defaultScheduler,
    context: null,
    project: null
}

const titleChangedReducer = (state, action) => {
    
    state.title = action.payload;
}

const schedulerChangedReducer = (state, action) => {
    
    state.scheduler = action.payload ?? defaultScheduler;
}

const contextChangedReducer = (state, action) => {
    
    state.context = action.payload;
}

const projectChangedReducer = (state, action) => {
    
    state.project = action.payload;
}

const newThingSlice = createSlice({
    name: "newThing",
    initialState: initialState,
    reducers: {
        titleChanged: titleChangedReducer,
        schedulerChanged: schedulerChangedReducer,
        contextChanged: contextChangedReducer,
        projectChanged: projectChangedReducer
    }
})

export const {
    titleChanged,
    schedulerChanged,
    contextChanged,
    projectChanged
} = newThingSlice.actions;
export const newThingReducer = newThingSlice.reducer;