import {Glyphicon} from "../../common/glyphs";
import {InactiveProjectsSwitch} from "../common/InactiveProjectsSwitch";
import {ProjectList} from "./ProjectList";
import {useGetProjectsQuery} from "./slices/projectApiSlice";
import {LeftPanelGroup} from "../left-panel/LeftPanelGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LeftPanelTool} from "../left-panel/LeftPanelTool";

export const LeftPanelProjects = ({...rest}) => {
    
    const { isLoading } = useGetProjectsQuery();
    
    const projects =
        isLoading
        ? <span>Loading</span>
        : <ProjectList {...rest} />;
        
    return (
        <>
            <LeftPanelGroup icon={Glyphicon.briefcase} title="Проекты">
                <LeftPanelTool className="me-4">
                    <InactiveProjectsSwitch classOn="text-primary" />
                </LeftPanelTool>
                <LeftPanelTool linkTo="/project/create" title="Создать проект" {...rest}>
                    <FontAwesomeIcon icon={Glyphicon.plus} />
                </LeftPanelTool>
            </LeftPanelGroup>
            {projects}
        </>
    );
}