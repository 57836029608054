import {useSelector} from "react-redux";
import {selectContextById} from "./slices/contextApiSlice";
import {selectSelected} from "../common/commonSlice";
import {LeftPanelBtn} from "../left-panel/LeftPanelBtn";

export const ContextBtn = ({contextId, ...rest}) => {

    const selected = useSelector(state => selectSelected(state));
    const context = useSelector(state => selectContextById(state, contextId));
    
    return (
        <LeftPanelBtn to={`/context/${context.id}`} active={selected.context?.id === context.id} title={context.title} {...rest}>
            {context.title}
        </LeftPanelBtn>
    );
}