import {Component} from "react";
import {ActionCreator} from "../../features/actions";
import {connect} from "react-redux";
import {GroupThingsBy} from "../../common/groupping";
import {Filter} from "../../common/filters";
import {Things} from "../../features/things/Things";

export class Deleted extends Component {

    constructor(props) {
        super(props);
        
        this.handleThingClick = this.handleThingClick.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this);
        this.componentWillMount = this.componentWillMount.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }
    
    handleThingClick(thing) {

        this.props.history.push('/thing/' + thing.id, { referer: this.props.location });
    }

    handleMoreClick() {

        this.props.onLoading && this.props.onLoading();
        this.props.things &&
            this.props.things.pagesLoaded &&
            this.props.onLoadDeleted &&
            this.props.onLoadDeleted(this.props.things.pagesLoaded + 1);
    }

    componentWillMount() {

        this.props.things && this.props.things.didInvalidate && this.props.onLoadDeleted(1);
    }

    componentDidMount() {

        if (this.props.onGroupSelected) this.props.onGroupSelected({
            filter: {
                type: Filter.deleted,
                title: Filter.getTitle(Filter.deleted),
                icon: Filter.getIconName(Filter.deleted)
            }
        });
    }

    render() {

        if (!this.props.things) return null;

        var loading = <h4 className="well well-sm">Загрузка...</h4>;
        var more = <div className="more-row" onClick={this.handleMoreClick }><h4>Ещё</h4></div>;

        var things = this.props.things.items.length === 0 && this.props.things.isFetching
            ? null
            : <Things things={this.props.things.items}
                      onThingClick={this.handleThingClick}
                      groupBy={GroupThingsBy.nothing}
                      getContext={this.props.getContext}
                      getProject={this.props.getProject}/>;

        return  <div className="col-xs-12">
                    {things}
                    {(this.props.things.isFetching ? loading : (this.props.things.pagesLoaded >= this.props.things.pageCount ? null : more))}
                </div>;
    }
}

function mapStateToProps(state) {

    return {

        things: state.things.deleted,

        getContext: function (id) {

            for (var i = 0; i < state.contexts.length; i++) {

                if (state.contexts[i].id === id) return state.contexts[i];
            }

            return undefined;
        },

        getProject: function (id) {
            for (var i = 0; i < state.projects.length; i++) {

                if (state.projects[i].id === id) return state.projects[i];
            }

            return undefined;
        }
    }
}

function mapDispatchToProps(dispatch) {

    return {

        onLoading: function () {

            dispatch(ActionCreator.thingDeletedLoading());
        },

        onLoadDeleted: function (pageNumber) {

            dispatch(ActionCreator.thingDeletedLoad(pageNumber));
        },

        onGroupSelected: function (selected) {

            dispatch(ActionCreator.commonSelectedChange(selected));
        }
    }
}

Deleted = connect(mapStateToProps, mapDispatchToProps)(Deleted);