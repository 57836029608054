import {configureStore} from "@reduxjs/toolkit";
import {commonReducer} from "./common/commonSlice";
import {contactReducer} from "./reducers/contact-reducer";
import {thingPlannerReducer} from "./reducers/thing-planner-reducer";
import {apiSlice} from "./api/apiSlice";
import {newProjectReducer} from "./projects/slices/newProjectSlice";
import {newContextReducer} from "./contexts/slices/newContextSlice";
import {newThingReducer} from "./things/slices/newThingSlice";

export const store =
    configureStore({
        reducer: {
            common: commonReducer,
            contacts: contactReducer,
            newProject: newProjectReducer,
            newContext: newContextReducer,
            newThing: newThingReducer,
            thingPlanners: thingPlannerReducer,
            [apiSlice.reducerPath]: apiSlice.reducer
        },
        middleware: getDefaultMiddleware => 
            getDefaultMiddleware().concat(apiSlice.middleware)
    });