import {Completed} from "../components/filter/Completed";
import {Deleted} from "../components/filter/Deleted";
import {Layout} from "../Layout";
import {Login} from "./account/Login";
import {Scheduler} from "./schedulers/schedulers";
import {ProjectCreate} from "./projects/ProjectCreate";
import {ContextCreate} from "./contexts/ContextCreate";
import {ThingsByContext} from "./contexts/ThingsByContext";
import {ThingsByProject} from "./projects/ThingsByProject";
import {ThingEdit} from "./things/ThingEdit";
import {ThingDetails} from "./things/ThingDetails";
import {ContextEdit} from "./contexts/ContextEdit";
import {ProjectEdit} from "./projects/ProjectEdit";
import {ThingsByScheduler} from "./schedulers/ThingsByScheduler";
import {Authorize} from "./account/Authorize";
import {Register} from "./account/Register";

export const browserRoutes = [
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/register",
        element: <Register />
    },
    {
        path: "/",
        element: <Authorize element={Layout()} />,// TODO why I can't use component without brackets (w/o calling function)?
        children: [
            {
                index: true,
                element: <ThingsByScheduler schedulerId={Scheduler.today} />
            },
            {
                path: "inbox",
                element: <ThingsByScheduler schedulerId={Scheduler.inbox} />
            },
            {
                path: "next",
                element: <ThingsByScheduler schedulerId={Scheduler.next} />
            },
            {
                path: "tomorrow",
                element: <ThingsByScheduler schedulerId={Scheduler.tomorrow} />
            },
            {
                path: "scheduled",
                element: <ThingsByScheduler schedulerId={Scheduler.scheduled} />
            },
            {
                path: "someday",
                element: <ThingsByScheduler schedulerId={Scheduler.someday} />
            },
            {
                path: "waiting",
                element: <ThingsByScheduler schedulerId={Scheduler.waiting} />
            },
            {
                path: "completed",
                element: <Completed/>
            },
            {
                path: "deleted",
                element: <Deleted/>
            },
            {
                path: "context/create",
                element: <ContextCreate />
            },
            {
                path: "/context/:id/edit",
                element: <ContextEdit />
            },
            {
                path: "context/:id",
                element: <ThingsByContext />
            },
            {
                path: "project/create",
                element: <ProjectCreate />
            },
            {
                path: "project/:id/edit",
                element: <ProjectEdit />
            },
            {
                path: "project/:id",
                element: <ThingsByProject />
            },
            {
                path: "thing/:id",
                element: <ThingDetails />
            },
            {
                path: "thing/:id/edit",
                element: <ThingEdit />
            }
        ]
    }
]