import {useGetUserQuery} from "./accountApiSlice";
import {Navigate} from "react-router-dom";

export const Authorize = ({ element }) => {

    const {
        data: account,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetUserQuery();
    
    return (
        isLoading
        ? <span>Loading</span>
        : isSuccess
            ? account
                ? element
                : <Navigate replace to="/login" />
            : <span>error</span>);
}