export const Server = (function () {

    var pageSize = 20;

    return {

        user: function (args) {

            var url = "/api/account/details";

            //return fetch(url, {credentials: 'include'}).then(response => response.json());

            // $.ajax({
            //
            //     url: url,
            //     dataType: "json",
            //     cache: false,
            //     success: function (user) {
            //
            //         if (args && args.success) args.success(user);
            //
            //     }.bind(this),
            //
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //     }
            // });
        },

        contacts: function (args) {

            var url = "/api/contact";

            // $.ajax({
            //
            //     url: url,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (args && args.success) args.success(response.payload.contacts);
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //     }
            // });

        },

        contexts: function(args) {

            var url = "/api/context";

            // $.ajax({
            //
            //     url: url,
            //     data: { deleted: args.deleted },
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (args && args.success) args.success(response.payload.contexts);
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        things: function(args) {

            var url = "/api/thing";

            // $.ajax({
            //
            //     url: url,
            //     dataType: "json",
            //     cache: false,
            //     success: function (things) {
            //
            //         if (args && args.success) args.success(things);
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        thingCreate: function(args) {

            if (!args || !args.thing) return;

            var thing = args.thing;
            var url = "/api/thing/create";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: thing,
            //     cache: false,
            //
            //     success: function (response) {
            //
            //         if (args.success) args.success(response.items[0]);
            //
            //     },
            //
            //     error: function (xhr, status, err) {
            //
            //         if (args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        thingComplete: function(args) {

            if (!args || !args.thing) return;

            var thing = args.thing;
            var url = "/api/thing/complete/" + thing.id;

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (args.success) args.success(response.items[0]);
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args.error) args.error(xhr, status, err);
            //     }
            // });
        },

        thingUncomplete: function (args) {

            if (!args || !args.thing) return;

            var thing = args.thing;
            var url = "/api/thing/uncomplete/" + thing.id;

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (args.success) args.success(response.items[0]);
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args.error) args.error(xhr, status, err);
            //     }
            // });
        },

        thingDelete: function (args) {

            if (!args || !args.thing) return;

            var thing = args.thing;
            var url = "/api/thing/delete/" + thing.id;

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (args.success) args.success(response.items[0]);
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args.error) args.error(xhr, status, err);
            //     }
            // });
        },

        thingUndelete: function (args) {

            if (!args || !args.thing) return;

            var thing = args.thing;
            var url = "/api/thing/undelete/" + thing.id;

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (args.success) args.success(response.items[0]);
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args.error) args.error(xhr, status, err);
            //     }
            // });
        },

        thingEdit: function(args) {

            if (!args || !args.thing) return;

            var thing = args.thing;
            var url = "/api/thing/edit/" + thing.id;

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: thing,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         response.error
            //             ? args.error && args.error(response.payload)
            //             : args.success && args.success(response.payload);
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         args.error && args.error(err);
            //
            //     }
            // });
        },

        completed: function (args) {

            var url = "/api/thing/completed";

            var data = {};

            if (args.pageNumber) {

                data.size = pageSize;
                data.number = args.pageNumber;
            }

            // $.ajax({
            //
            //     url: url,
            //     data: data,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (response.status === 'success') {
            //
            //             args && args.success && args.success(response);
            //
            //         } else {
            //
            //             args && args.error && args.error(null, response.status, response.meta.error);
            //         }
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        deleted: function (args) {

            var url = "/api/thing/deleted";

            var data = {};

            if (args.pageNumber) {

                data.size = pageSize;
                data.number = args.pageNumber;
            }

            // $.ajax({
            //
            //     url: url,
            //     data: data,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (response.status === 'success') {
            //
            //             args && args.success && args.success(response);
            //
            //         } else {
            //
            //             args && args.error && args.error(null, response.status, response.meta.error);
            //         }
            //     },
            //     error: function (xhr, status, err) {
            //
            //         args && args.error && args.error(xhr, status, err);
            //
            //     }
            // });
        },

        thingPlanners: function(args) {

            var url = "/api/thingplanner";

            // $.ajax({
            //
            //     url: url,
            //     dataType: "json",
            //     cache: false,
            //     success: function (planners) {
            //
            //         if (args && args.success) args.success(planners);
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        thingPlannerComplete: function (args) {

            if (!args || !args.thingPlanner) return;

            var planner = args.thingPlanner;
            var url = "/api/thingplanner/complete/" + planner.id;

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     cache: false,
            //     success: function (response) {
            //
            //         response.error
            //             ? args.error && args.error(response.payload)
            //             : args.success && args.success(response.payload);
            //     },
            //     error: function (xhr, status, err) {
            //
            //         args.error && args.error(err);
            //     }
            // });
        },

        thingPlannerUncomplete: function (args) {

            if (!args || !args.thingPlanner) return;

            var planner = args.thingPlanner;
            var url = "/api/thingplanner/uncomplete/" + planner.id;

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     cache: false,
            //     success: function (response) {
            //
            //         response.error
            //             ? args.error && args.error(response.payload)
            //             : args.success && args.success(response.payload);
            //     },
            //     error: function (xhr, status, err) {
            //
            //         args.error && args.error(err);
            //     }
            // });
        },

        thingPlannerEdit: function (args) {

            if (!args || !args.thingPlanner) return;

            var thingPlanner = args.thingPlanner;
            var url = "/api/thingplanner/edit/" + thingPlanner.id;

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: thingPlanner,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         response.error
            //             ? args.error && args.error(response.payload)
            //             : args.success && args.success(response.payload.thingPlanner);
            //
            //         //if (!response.error) {
            //
            //         //    args.success && args.success(response.payload.thingPlanner);
            //         //} else {
            //
            //         //    args.error && args.error(response.payload);
            //         //}
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         args.error && args.error(err);
            //
            //     }
            // });
        },

        planThings: function (args) {

            var url = "/api/thingplanner/planthings";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: args.data,
            //     dataType: "json",
            //     cache: false,
            //     success: function (things) {
            //
            //         if (args && args.success) args.success(things);
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        projects: function (args) {

            var url = "/api/project";

            // $.ajax({
            //
            //     url: url,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         var visibleProjects = [];
            //
            //         // TODO фильтрация должна быть в другом месте
            //         for (var i = 0; i < response.length; i++) {
            //
            //             if (!response[i].done) visibleProjects.push(response[i]);
            //         }
            //
            //         if (args && args.success) args.success(visibleProjects);
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //     }
            // });

        },

        projectCreate: function (args) {

            if (!args || !args.project) return;

            var project = args.project;
            var url = "/api/project/create";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: project,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (args && args.success) args.success(response.payload.project);
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        projectEdit: function (args) {

            if (!args || !args.project) return;

            var project = args.project;
            var url = "/api/project/edit/";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: project,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (args && args.success) args.success(response.payload.project);
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        projectComplete: function (args) {

            if (!args || !args.project) return;

            var project = args.project;
            var url = "/api/project/complete/";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: project,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (args && args.success) args.success(response.payload);
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        projectShare: function (args) {

            if (!args || !args.project || !args.contact) return;

            var url = "/api/project/share/";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: {
            //         id: args.project.id,
            //         contact: args.contact
            //     },
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (response.status === "success" && args.success) {
            //
            //             args.success();
            //
            //         } else if (response.status === "error" && args.error) {
            //
            //             args.error(null, response.status, null);
            //         }
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        projectActivate: function (args) {

            if (!args || !args.project) return;

            var project = args.project;
            var url = "/api/project/activate/";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: project,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (response.error) {
            //
            //             args.error && args.error(response.payload);
            //
            //         } else {
            //
            //             args.success && args.success(response.payload);
            //         }
            //     },
            //     error: function (xhr, status, err) {
            //
            //         args.error && args.error(err);
            //
            //     }
            // });
        },

        projectDeactivate: function (args) {

            if (!args || !args.project) return;

            var project = args.project;
            var url = "/api/project/deactivate/";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: project,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (response.error) {
            //
            //             args.error && args.error(response.payload);
            //
            //         } else {
            //
            //             args.success && args.success(response.payload);
            //         }
            //     },
            //     error: function (xhr, status, err) {
            //
            //         args.error && args.error(err);
            //
            //     }
            // });
        },

        contextCreate: function (args) {

            if (!args || !args.context) return;

            var context = args.context;
            var url = "/api/context/create";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: context,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (args && args.success) args.success(response.payload.context);
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        contextEdit: function (args) {

            if (!args || !args.context) return;

            var context = args.context;
            var url = "/api/context/edit/";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: context,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (args && args.success) args.success(response.payload.context);
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        contextDelete: function (args) {

            if (!args || !args.context) return;

            var context = args.context;
            var url = "/api/context/delete/";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: context,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (args && args.success) args.success(response.payload);
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args && args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        contextShare: function (args) {

            if (!args || !args.context || !args.contact) return;

            var url = "/api/context/share/";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: {
            //         id: args.context.id,
            //         contact: args.contact
            //     },
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (response.status === "success" && args.success) {
            //
            //             args.success();
            //
            //         } else if (response.status === "error" && args.error) {
            //
            //             args.error(null, response.status, null);
            //         }
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        contextJoin: function (args) {

            if (!args || !args.context || !args.parent) return;

            var url = "/api/context/join/";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: {
            //         id: args.context.id,
            //         parentId: args.parent.id
            //     },
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (response.status === "success" && args.success) {
            //
            //             args.success(response.payload.contexts);
            //
            //         } else if (response.status === "error" && args.error) {
            //
            //             args.error(null, response.status, null);
            //         }
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },

        contextUnjoin: function (args) {

            if (!args || !args.context) return;

            var url = "/api/context/unjoin/";

            // $.ajax({
            //
            //     url: url,
            //     method: "POST",
            //     data: args.context,
            //     dataType: "json",
            //     cache: false,
            //     success: function (response) {
            //
            //         if (response.status === "success" && args.success) {
            //
            //             args.success(response.payload.context);
            //
            //         } else if (response.status === "error" && args.error) {
            //
            //             args.error(null, response.status, null);
            //         }
            //
            //     },
            //     error: function (xhr, status, err) {
            //
            //         if (args.error) args.error(xhr, status, err);
            //
            //     }
            // });
        },


    };
})();