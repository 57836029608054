import {useDispatch, useSelector} from "react-redux";
import {titleChanged} from "./slices/newContextSlice";
import {useCreateContextMutation} from "./slices/contextApiSlice";
import {useNavigate} from "react-router-dom";

export const ContextCreate = () => {

    const newContext = useSelector(state => state.newContext);
    const [save] = useCreateContextMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleTitleChange = (event) => {

        event.preventDefault();
        dispatch(titleChanged(event.target.value));
    }

    const handleSubmit = async (event) => {

        event.preventDefault();
        dispatch(titleChanged(""));
        const result = await save(newContext).unwrap();
        
        navigate(`/context/${result.id}`);
    }

    return (
        <div className="col-xs-12">
            <form method="POST" onSubmit={handleSubmit}>
                <div className="form-floating mb-2">
                    <input id="title" className="form-control" value={newContext.title} onChange={handleTitleChange} placeholder="Заголовок"/>
                    <label htmlFor="title">Заголовок</label>
                </div>
                <button className="btn btn-outline-secondary" type="submit">Сохранить</button>
            </form>
        </div>
    );
}