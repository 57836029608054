import "./css/reset.css";
import "./css/bootstrap.sass";
import "./css/main.sass";
import "./css/site.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React, {StrictMode} from "react";
import { createRoot } from "react-dom/client";
import {createBrowserRouter} from "react-router-dom";
import {App} from './App';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import {browserRoutes} from "./features/browserRoutes";
import {store} from "./features/store";
import {Provider} from "react-redux";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const router = createBrowserRouter(browserRoutes);

root.render(
    <StrictMode>
        <Provider store={store}>
            <App router={router} />
        </Provider>
    </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
