import {Scheduler} from "../schedulers/schedulers";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    selectThingById,
    useUpdateThingMutation
} from "./slices/thingApiSlice";
import {useEffect, useState} from "react";
import {selectAllContexts} from "../contexts/slices/contextApiSlice";
import {selectAllProjects} from "../projects/slices/projectApiSlice";
import $ from "jquery";
import {DateCalendar} from "@mui/x-date-pickers";
import {afterTomorrow, formatUtc, parseUtcAsLocal, today, tomorrow} from "../../common/date-time";
import {selectSelected} from "../common/commonSlice";

export const ThingEditor = ({ id }) => {
    
    const thing = useSelector(state => selectThingById(state, id));
    const contexts = useSelector(state => selectAllContexts(state));
    const projects = useSelector(state => selectAllProjects(state));
    const selected = useSelector(state => selectSelected(state));
    
    const [ save ] = useUpdateThingMutation();
    const navigate = useNavigate();

    const [title, setTitle] = useState(thing?.title ?? "");
    const [description, setDescription] = useState(thing?.description ?? "");
    const [schedulerType, setSchedulerType] = useState(thing?.schedulerType ?? Scheduler.inbox);
    const [startDate, setStartDate] = useState(parseUtcAsLocal(thing?.start));
    const [contextId, setContextId] = useState(thing?.contextId);
    const [projectId, setProjectId] = useState(thing?.projectId);

    useEffect(() => {
        
        thing.schedulerType === Scheduler.scheduled && $(".MuiDateCalendar-root").slideDown(200);
        
    }, [id]);
    
    const navigateToSelectedOrBack = () => {
        
        let to =
            selected.context
            ? `/context/${selected.context.id}` 
            : selected.project
            ? `/project/${selected.project.id}`
            : selected.scheduler
            ? selected.scheduler.type === Scheduler.today
                ? "/"
                : `/${selected.scheduler.type}`
            : -1;
        
        navigate(to);
    }
    
    const handleSubmit = async (event) => {
        
        event.preventDefault();
        
        const scheduler =
            schedulerType === Scheduler.today
            || schedulerType === Scheduler.tomorrow
                ? Scheduler.scheduled
                : schedulerType;
        
        const start =
            schedulerType === Scheduler.today
            || schedulerType === Scheduler.tomorrow
            || schedulerType === Scheduler.scheduled
                ? formatUtc(startDate)
                : null;
        
        await save({
            id: thing.id,
            title,
            description,
            schedulerType: scheduler,
            start, 
            contextId,
            projectId
        });
        
        navigateToSelectedOrBack();
    }
    
    const handleTitleChange = (event) => {
        
        setTitle(event.target.value);
    }
    
    const handleDescriptionChange = (event) => {
        
        setDescription(event.target.value);
    }
    
    const handleSchedulerChange = (event) => {
        
        setSchedulerType(event.target.value);

        if (event.target.value === Scheduler.today) {
            
            setStartDate(today());
        }
        else if (event.target.value === Scheduler.tomorrow) {

            setStartDate(tomorrow());
        }
        else if (event.target.value === Scheduler.scheduled && !startDate) {
            
            setStartDate(afterTomorrow());
        }

        if (event.target.value === Scheduler.scheduled) {
            
            $(".MuiDateCalendar-root").slideDown(200);
            
        } else {
            
            $(".MuiDateCalendar-root").slideUp(200);
        }
    }
    
    const handleStartDateChange = (value, state) => {
        
        if (state !== "finish") return;
        
        setStartDate(value);
    }
    
    const handleContextChange = (event) => {

        const contextId =
            event.target.value === ""
                ? null
                : Number(event.target.value);
        
        setContextId(contextId);
    }
    
    const handleProjectChange = (event) => {

        const projectId =
            event.target.value === ""
                ? null
                : Number(event.target.value);
        
        setProjectId(projectId);
    }

    const contextList = contexts.map(context => <option key={context.id} value={context.id}>{context.title}</option>);
    const projectList = projects.map(project => <option key={project.id} value={project.id}>{project.title}</option>);
    
    return (
        <div className="col-xs-12">
             <div className="row">
                 <div className="col-xs-12">
                     <div className="mb-2">
                         {/*<span className="toolbar-btn">{completeTool}</span>*/}
                         {/*<span className="toolbar-btn">{deleteTool}</span>*/}
                     </div>
                 </div>
             </div>
             <form action="#" method="POST" onSubmit={handleSubmit}>
                 <div className="mb-2">
                     <button className="btn btn-outline-secondary" type="submit">Сохранить</button>
                 </div>
                 <div className="form-floating mb-2">
                     <input id="title" className="form-control" value={title} onChange={handleTitleChange} placeholder="Заголовок" />
                     <label htmlFor="title">Заголовок</label>
                 </div>
                 <div className="form-floating mb-2">
                     <textarea id="description" className="form-control textarea-rows-5" value={description} onChange={handleDescriptionChange} placeholder="Описание"></textarea>
                     <label htmlFor="description">Описание</label>
                 </div>
                 <div className="form-floating mb-2">
                     <select id="schedulerType" className="form-select" value={schedulerType} onChange={handleSchedulerChange}>
                         <option value={Scheduler.inbox}>{Scheduler.getTitle(Scheduler.inbox)}</option>
                         <option value={Scheduler.today}>{Scheduler.getTitle(Scheduler.today)}</option>
                         <option value={Scheduler.next}>{Scheduler.getTitle(Scheduler.next)}</option>
                         <option value={Scheduler.tomorrow}>{Scheduler.getTitle(Scheduler.tomorrow)}</option>
                         <option value={Scheduler.scheduled}>Запланировать</option>{/* TODO повелительное наклонение в Scheduler.getTitle() */}
                         <option value={Scheduler.someday}>{Scheduler.getTitle(Scheduler.someday)}</option>
                         <option value={Scheduler.waiting}>{Scheduler.getTitle(Scheduler.waiting)}</option>
                     </select>
                     <label htmlFor="schedulerType">Планирование</label>
                 </div>
                 <div id="date-picker-start" className="mb-2">
                     <DateCalendar value={startDate} onChange={handleStartDateChange} disablePast views={["year", "month", "day"]} sx={{display: "none"}} />
                 </div>
                 {/*<div id="repeat-panel" style={{display: "none"}}>*/}
                 {/*    <div className="mb-2">*/}
                 {/*        /!*{editPlannerLink}*!/*/}
                 {/*    </div>*/}
                 {/*    <div className="mb-2">*/}
                 {/*        <div className="input-group">*/}
                 {/*            <span className="input-group-addon">*/}
                 {/*        <input id="repeat" type="checkbox" checked={this.state.repeat} onChange={this.handleRepeatChange} disabled={this.state.plannerId ? "disabled" : ""} />*/}
                 {/*    </span>*/}
                 {/*            <span className="form-control">Повторять</span>*/}
                 {/*        </div>*/}
                 {/*    </div>*/}
                 {/*    <div id="repeat-panel-details" style={{display: "none"}}>*/}
                 {/*        <div className="mb-2">*/}
                 {/*            <select id="plannerType" className="form-control" value={this.state.plannerType} onChange={this.handlePlannerTypeChange} disabled={this.state.plannerId ? "disabled" : ""}>*/}
                 {/*                <option value={PlannerType.daily.index}>{PlannerType.daily.title}</option>*/}
                 {/*                /!*<option value={PlannerType.weekly.index}>{PlannerType.weekly.title}</option>*/}
                 {/*        <option value={PlannerType.weekday.index}>{PlannerType.weekday.title}</option>*!/*/}
                 {/*                <option value={PlannerType.monthly.index}>{PlannerType.monthly.title}</option>*/}
                 {/*                <option value={PlannerType.yearly.index}>{PlannerType.yearly.title}</option>*/}
                 {/*            </select>*/}
                 {/*        </div>*/}
                 {/*        <div className="mb-2">*/}
                 {/*            <input id="repeatPeriodCount" type="number" className="form-control" value={this.state.repeatPeriodCount} onChange={this.handleRepeatPeriodCountChange} disabled={this.state.plannerId ? "disabled" : ""} data-val="true" data-val-number="Введите число." data-val-required="Поле должно быть заполнено." />*/}
                 {/*        </div>*/}
                 {/*    </div>*/}
                 {/*</div>*/}
                 <div className="form-floating mb-2">
                     <select id="contextId" className="form-select" value={contextId ?? ""} onChange={handleContextChange}>
                         <option value=""></option>{contextList}
                     </select>
                     <label htmlFor="contextId">Контекст</label>
                 </div>
                 <div className="form-floating mb-2">
                     <select id="projectId" className="form-select" value={projectId ?? ""} onChange={handleProjectChange}>
                         <option value=""></option>{projectList}
                     </select>
                     <label htmlFor="projectId">Проект</label>
                 </div>
                 <div className="mb-2">
                     <button className="btn btn-outline-secondary" type="submit">Сохранить</button>
                 </div>
             </form>
         </div>
    );
}

// var { Link } = ReactRouterDOM;
//
// var ThingEdit = React.createClass({
//
//     getThingId: function() { return this.props.match.params.id },
//
//     combineState: function(thing, thingPlanner) {
//        
//         if (!thing) return {
//
//             title: '',
//             description: '',
//             schedulerType: Scheduler.getIndex(Scheduler.inbox),
//             repeat: false,
//             plannerType: 0,
//             repeatPeriodCount: 0,
//             start: '',
//             projectId: '',
//             contextId: ''
//         };
//
//         var state = Object.assign({}, thing, {
//
//             plannerId: '', // Для проверки, загружен ли планер (при перезагрузке страницц редактирования)
//             schedulerType: Scheduler.getIndex(thing.scheduler),
//             // null нельзя маппить в select (ругается React)
//             start: thing.start || '',
//             projectId: thing.projectId || '',
//             contextId: thing.contextId || ''
//         });
//
//         if (thingPlanner) {
//
//             state.plannerId = thingPlanner.id;
//             state.repeat = true;
//             state.plannerType = thingPlanner.type;
//             state.repeatPeriodCount = thingPlanner.periodCount;
//         }
//
//         return state;
//     },
//
//     getInitialState: function() {
//
//         var thing = this.props.things.items.find(function (t) { return t.id === this.getThingId() }.bind(this));
//         var thingPlanner = thing && thing.plannerId
//             ? this.props.thingPlanners.find(tp => tp.id === thing.plannerId)
//             : null;
//
//         return this.combineState(thing, thingPlanner);
//     },
//
//     navigateToReferer: function(thing) {
//         // or to thing's scheduler
//        
//         var returnUrl = this.props.location.state && this.props.location.state.referer
//             ? this.props.location.state.referer
//             : '/' + Scheduler.getUrlPath(thing.scheduler);
//         this.props.history.push(returnUrl);
//     },
//
//     handleSelectedChanged: function () {
//
//         if (this.props.selected &&
//         (this.props.selected.scheduler ||
//             this.props.selected.context ||
//             this.props.selected.project ||
//             this.props.selected.filter)) return;
//
//         var thing = this.props.things.items.find(function (t) { return t.id === this.getThingId() }.bind(this));
//
//         if (!thing) return;
//
//         this.props.onSelectedChanged &&
//             this.props.onSelectedChanged({
//                 scheduler: {
//                     type: thing.scheduler,
//                     title: Scheduler.getTitle(thing.scheduler),
//                     icon: Scheduler.getIconName(thing.scheduler)
//                 }
//             });
//     },
//
//     handleTitleChange: function (e) {
//
//         this.setState({ title: e.target.value });
//     },
//
//     handleDescriptionChange: function (e) {
//
//         this.setState({ description: e.target.value });
//     },
//
//     handleSchedulerChange: function (e) {
//
//         this.setState({ schedulerType: parseInt(e.target.value) }, this.updateVisibility);
//     },
//
//     handleRepeatChange: function (e) {
//
//         this.setState({ repeat: e.target.checked }, this.updateVisibility);
//     },
//
//     handlePlannerTypeChange: function(e){
//
//         this.setState({ plannerType: e.target.value });
//     },
//
//     handleRepeatPeriodCountChange: function(e) {
//
//         this.setState({ repeatPeriodCount: e.target.value });
//     },
//
//     handleProjectChange: function (e) {
//
//         this.setState({ projectId: e.target.value });
//     },
//
//     handleContextChange: function (e) {
//
//         this.setState({ contextId: e.target.value });
//     },
//
//     handleSubmit: function (e) {
//
//         e.preventDefault();
//         if (this.props.onSubmit) this.props.onSubmit(this.state);
//         this.navigateToReferer({ scheduler: Scheduler.getByIndex(this.state.schedulerType) });
//     },
//
//     handleDelete: function (thing) {
//
//         this.props.onDelete && this.props.onDelete(thing);
//         this.navigateToReferer(thing);
//     },
//
//     handleUndelete: function (thing) {
//
//         this.props.onUndelete && this.props.onUndelete(thing);
//         this.navigateToReferer(thing);
//     },
//
//     handleComplete: function (thing) {
//
//         this.props.onComplete && this.props.onComplete(thing);
//         this.navigateToReferer(thing);
//     },
//
//     handleUncomplete: function (thing) {
//
//         this.props.onUncomplete && this.props.onUncomplete(thing);
//         this.navigateToReferer(thing);
//     },
//
//     updateVisibility: function () {
//
//         if (this.state.schedulerType === Scheduler.getIndex(Scheduler.scheduled)) {
//             $("#datepicker-start").slideDown(100);
//         } else {
//             $("#datepicker-start").slideUp(200);
//         }
//
//         if (this.state.schedulerType === Scheduler.getIndex(Scheduler.today)
//             || this.state.schedulerType === Scheduler.getIndex(Scheduler.tomorrow)
//             || this.state.schedulerType === Scheduler.getIndex(Scheduler.scheduled)) {
//             $("#repeat-panel").slideDown(100);
//         } else {
//             $("#repeat-panel").slideUp(200);
//         }
//
//         if (this.state.repeat) {
//             $('#repeat-panel-details').slideDown(100);
//         } else {
//             $('#repeat-panel-details').slideUp(200);
//         }
//     },
//
//     componentDidMount: function() {
//
//         this.handleSelectedChanged();
//
//         // init datepicker
//         $("#datepicker-start").datepicker();
//         $("#datepicker-start").on("changeDate", function () {
//
//             this.setState({ start: $("#datepicker-start").datepicker('getFormattedDate') });
//         }.bind(this));
//
//         this.updateVisibility();
//     },
//
//     componentWillReceiveProps: function(nextProps) {
//
//         var thing = nextProps.things.items.find(function (t) { return t.id === this.getThingId() }.bind(this));
//
//         if (!thing) return;
//
//         if (this.state.id && (!thing.plannerId || this.state.plannerId)) return;
//
//         var thingPlanner = thing.plannerId
//             ? nextProps.thingPlanners.find(tp => tp.id === thing.plannerId)
//             : null;
//        
//         this.setState(this.combineState(thing, thingPlanner));
//     },
//
//     componentDidUpdate: function () {
//
//         this.handleSelectedChanged();
//         this.updateVisibility();
//     },
//
//     render: function () {
//        
//         var completeTool = this.state.done
//             ? <ToolForm entity={this.state} onSubmit={this.handleUncomplete} glyphClass={Glyphicon.uncomplete} title="Не готово" />
//             : <ToolForm entity={this.state} onSubmit={this.handleComplete} glyphClass={Glyphicon.complete} title="Готово" />;
//        
//         var deleteTool = this.state.deleted
//             ? <ToolForm entity={this.state} onSubmit={this.handleUndelete} glyphClass={Glyphicon.undelete} title="Восстановить" />
//             : <ToolForm entity={this.state} onSubmit={this.handleDelete} glyphClass={Glyphicon.delete} title="Удалить" />;
//
//         var editPlannerLink = this.state.plannerId
//             ? <Link to={{pathname: "/thingplanner/edit/" + this.state.plannerId}}>Редактировать планировщик</Link>
//             : null;
//
//         var projects = this.props.projects.ids.sort((p1, p2) => {
//
//             if (this.props.projects.byId[p1].title.toLowerCase() < this.props.projects.byId[p2].title.toLowerCase()) return -1;
//             if (this.props.projects.byId[p1].title.toLowerCase() > this.props.projects.byId[p2].title.toLowerCase()) return 1;
//             return 0;
//
//         }).map(id => { return <option key={id} value={this.props.projects.byId[id].id }>{this.props.projects.byId[id].title}</option>; });
//
//         var contexts = this.props.contexts.sort(function (c1, c2) {
//
//             if (c1.title.toLowerCase() < c2.title.toLowerCase()) return -1;
//             if (c1.title.toLowerCase() > c2.title.toLowerCase()) return 1;
//             return 0;
//
//         }).map(function (context) { return <option key={context.id} value={context.id }>{context.title}</option>; }.bind(this));
//
//         return  <div className="col-xs-12">
//                     <div className="row">
//                         <div className="col-xs-12">
//                             <div className="mb-2">
//                                 <span className="toolbar-btn">{completeTool}</span>
//                                 <span className="toolbar-btn">{deleteTool}</span>
//                             </div>
//                         </div>
//                     </div>
//                     <form method="POST" onSubmit={this.handleSubmit}>
//                         <div className="mb-2">
//                             <button className="btn btn-default" type="submit">Сохранить</button>
//                         </div>
//                         <div className="mb-2">
//                             <label htmlFor="title">Заголовок</label>
//                             <input id="title" className="form-control" value={this.state.title} onChange={this.handleTitleChange} />
//                         </div>
//                         <div className="mb-2">
//                             <label htmlFor="description">Описание</label>
//                             <textarea id="description" className="form-control" rows="10" value={this.state.description} onChange={this.handleDescriptionChange}></textarea>
//                         </div>
//                         <div className="mb-2">
//                             <select id="schedulerType" className="form-control" value={this.state.schedulerType} onChange={this.handleSchedulerChange}>
//                                 <option value={Scheduler.getIndex(Scheduler.inbox)}>{Scheduler.getTitle(Scheduler.inbox)}</option>
//                                 <option value={Scheduler.getIndex(Scheduler.today)}>{Scheduler.getTitle(Scheduler.today)}</option>
//                                 <option value={Scheduler.getIndex(Scheduler.next)}>{Scheduler.getTitle(Scheduler.next)}</option>
//                                 <option value={Scheduler.getIndex(Scheduler.tomorrow)}>{Scheduler.getTitle(Scheduler.tomorrow)}</option>
//                                 <option value={Scheduler.getIndex(Scheduler.scheduled)}>Запланировать</option>  {/* TODO повелительное наклонение */}
//                                 <option value={Scheduler.getIndex(Scheduler.someday)}>{Scheduler.getTitle(Scheduler.someday)}</option>
//                                 <option value={Scheduler.getIndex(Scheduler.waiting)}>{Scheduler.getTitle(Scheduler.waiting)}</option>
//                             </select>
//                         </div>
//                         <div className="mb-2">
//                             <div id="datepicker-start" data-date-language="ru" data-date={this.state.start ? formatDate(new Date(this.state.start)) : new Date()} style={{display: "none"}}></div>
//                         </div>
//                         <div id="repeat-panel" style={{display: "none"}}>
//                             <div className="mb-2">
//                                 {editPlannerLink}
//                             </div>
//                             <div className="mb-2">
//                                 <div className="input-group">
//                                     <span className="input-group-addon">
//                                         <input id="repeat" type="checkbox" checked={this.state.repeat} onChange={this.handleRepeatChange} disabled={this.state.plannerId ? "disabled" : ""} />
//                                     </span>
//                                     <span className="form-control">Повторять</span>
//                                 </div>
//                             </div>
//                             <div id="repeat-panel-details" style={{display: "none"}}>
//                                 <div className="mb-2">
//                                     <select id="plannerType" className="form-control" value={this.state.plannerType} onChange={this.handlePlannerTypeChange} disabled={this.state.plannerId ? "disabled" : ""}>
//                                         <option value={PlannerType.daily.index}>{PlannerType.daily.title}</option>
//                                         {/*<option value={PlannerType.weekly.index}>{PlannerType.weekly.title}</option>
//                                         <option value={PlannerType.weekday.index}>{PlannerType.weekday.title}</option>*/}
//                                         <option value={PlannerType.monthly.index}>{PlannerType.monthly.title}</option>
//                                         <option value={PlannerType.yearly.index}>{PlannerType.yearly.title}</option>
//                                     </select>
//                                 </div>
//                                 <div className="mb-2">
//                                     <input id="repeatPeriodCount" type="number" className="form-control" value={this.state.repeatPeriodCount} onChange={this.handleRepeatPeriodCountChange} disabled={this.state.plannerId ? "disabled" : ""} data-val="true" data-val-number="Введите число." data-val-required="Поле должно быть заполнено." />
//                                 </div>
//                             </div>
//                             </div>
//                         <div className="mb-2">
//                             <label htmlFor="projectId">Проект</label>
//                             <select id="projectId" className="form-control" value={this.state.projectId} onChange={this.handleProjectChange}>
//                                 <option value=""></option>{projects}
//                             </select>
//                         </div>
//                         <div className="mb-2">
//                             <label htmlFor="contextId">Контекст</label>
//                             <select id="contextId" className="form-control" value={this.state.contextId} onChange={this.handleContextChange}>
//                                 <option value=""></option>{contexts}
//                             </select>
//                         </div>
//                         <div className="mb-2">
//                             <button className="btn btn-default" type="submit">Сохранить</button>
//                         </div>
//                     </form>
//                 </div>;
//     }
// });
//
// var mapThingEditStateToProps = function (state) {
//
//     return {
//
//         selected: state.common.selected,
//         things: state.things,
//         thingPlanners: state.thingPlanners.items,
//         projects: state.projects,
//         contexts: state.contexts
//     }
// };
//
// var mapThingEditDispatchToProps = function (dispatch) {
//
//     return {
//
//         onSelectedChanged: function (selected) {
//
//             dispatch(ActionCreator.commonSelectedChange(selected));
//         },
//
//         onSubmit: function (thing) {
//
//             dispatch(ActionCreator.thingEdit(thing));
//         },
//
//         onComplete: function (thing) {
//
//             dispatch(ActionCreator.thingComplete(thing));
//         },
//
//         onUncomplete: function (thing) {
//
//             dispatch(ActionCreator.thingUncomplete(thing));
//         },
//
//         onDelete: function (thing) {
//
//             dispatch(ActionCreator.thingDelete(thing));
//         },
//
//         onUndelete: function (thing) {
//
//             dispatch(ActionCreator.thingUndelete(thing));
//         }
//     }
// };
//
// ThingEdit = ReactRedux.connect(mapThingEditStateToProps, mapThingEditDispatchToProps)(ThingEdit);