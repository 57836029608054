import {NavLink} from "react-router-dom";
import {Filter} from "../../common/filters";
import {SchedulerList} from "../schedulers/SchedulerList";
import {LeftPanelContexts} from "../contexts/LeftPanelContexts";
import {LeftPanelProjects} from "../projects/LeftPanelProjects";

export const LeftPanel = () => {

    return (
        <aside className="left-sidebar">
            <ul className="nav nav-pills flex-column left-side-panel">
                <SchedulerList />
                <LeftPanelContexts />
                <LeftPanelProjects />
                <hr />
                {/*<li role="presentation"><NavLink to={'/completed'} className="list-group-item" activeclassname="active" >{Filter.getTitle(Filter.completed)}</NavLink></li>*/}
                {/*<li role="presentation"><NavLink to={'/deleted'} className="list-group-item" activeclassname="active" >{Filter.getTitle(Filter.deleted)}</NavLink></li>*/}
            </ul>
        </aside>
    );
}