import "../../css/login.sass"
import {Form, Link, Navigate} from "react-router-dom";
import {useLoginMutation} from "./accountApiSlice";

export const Login = () => {

    // TODO handle case: logged in user starts from /login path
    
    const [ login, { isFetching, isSuccess } ] = useLoginMutation();
    
    const handleSubmit = async (event) => {
        
        event.preventDefault();
        
        const userData = {
            username: event.target[0].value,
            password: event.target[1].value,
            rememberMe: event.target[2].checked
        }
        
        await login(userData).unwrap();
    }
    
    return (
        isFetching
        ? <span>Loading</span>
        : isSuccess
        ? <Navigate replace to="/"/>
        : <div className="centering-wrapper">
            <div className="centered">
                <Form onSubmit={handleSubmit}>
                    <div className="form-floating mb-2">
                        <input className="form-control" type="email" id="username" placeholder="name@example.com"/>
                        <label htmlFor="username">Электропочта</label>
                    </div>
                    <div className="form-floating mb-2">
                        <input type="password" id="password" className="form-control" placeholder="password"/>
                        <label htmlFor="password">Пароль</label>
                    </div>
                    <div className="form-check form-switch mb-2">
                        <input className="form-check-input" type="checkbox"  id="rememberMe" role="switch" />
                        <label className="form-check-label" htmlFor="rememberMe">Запомнить</label>
                    </div>
                    <div className="mb-2">
                        <button className="btn btn-outline-secondary form-control" type="submit">Вход</button>
                    </div>
                </Form>
                <Link to="/register" className="centered" >Регистрация</Link>
            </div>
        </div>
    );
}