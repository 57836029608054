export const Tags = {
    
    getAll: () => {
        
        let tags = [];
        
        for (let i in Tags) {
            
            if (!Tags.hasOwnProperty(i) || typeof Tags[i] !== 'string') continue;
            
            tags.push(i);
        }
        
        return tags;
    },
    
    user: "user",
    things: "things",
    projects: "projects",
    contexts: "contexts"
}