import {apiSlice} from "../api/apiSlice";
import {Tags} from "../api/constants";
import {createSelector} from "@reduxjs/toolkit";

const accountApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({

        register: builder.mutation({
            query: registration => ({
                url: "/account/register",
                method: "POST",
                body: registration
            }),
            invalidatesTags: [Tags.user]
        }),

        login: builder.mutation({
            query: login => ({
                url: "/account/login",
                method: "POST",
                body: login
            }),
            invalidatesTags: [Tags.user]
        }),
        
        logout: builder.mutation({
            query: _ => ({
                url: "account/logout",
                method: "POST"
            }),
            invalidatesTags: [Tags.user]
        }),
        
        getUser: builder.query({
            query: _ => ({
                url: "/account/getuser",
                method: "GET"
            }),
            providesTags: [Tags.user]
        })
    })
})

export const {
    useRegisterMutation,
    useLoginMutation,
    useLogoutMutation,
    useGetUserQuery
} = accountApiSlice

// selectors

const selectAccountResult = accountApiSlice.endpoints.getUser.select();

export const selectAccount = createSelector(
    selectAccountResult,
    result => result.data
);