import {useDispatch, useSelector} from "react-redux";
import {selectHideInactiveProjects, setHideInactiveProjects} from "./commonSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Glyphicon} from "../../common/glyphs";

export const InactiveProjectsSwitch = ({ className, classOn }) => {

    const hideInactiveProjects = useSelector(state => selectHideInactiveProjects(state));
    const dispatch = useDispatch();
    
    const setHideInactiveProjectsHandler = () => {
        dispatch(setHideInactiveProjects(!hideInactiveProjects));
    }
    
    const classes = [className, (hideInactiveProjects ? "" : classOn)].join(" ");
    const title = (hideInactiveProjects ? "Показать" : "Скрыть") + " неактивные проекты";
    
    return <FontAwesomeIcon onClick={setHideInactiveProjectsHandler} icon={Glyphicon.off} className={classes} title={title} />
}