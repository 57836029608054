import {Glyphicon} from "../../common/glyphs";
import {useSelector} from "react-redux";
import {selectProjectById} from "./slices/projectApiSlice";
import {selectSelected} from "../common/commonSlice";
import {LeftPanelBtn} from "../left-panel/LeftPanelBtn";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {LeftPanelTool} from "../left-panel/LeftPanelTool";

export const ProjectBtn = ({ projectId, ...rest }) => {

    const selected = useSelector(state => selectSelected(state));
    const project = useSelector(state => selectProjectById(state, projectId))
    
    const glyph = project.isActive
        ? null
        : <LeftPanelTool className="me-2">
            <FontAwesomeIcon icon={Glyphicon.pause} title="Проект неактивен" />
        </LeftPanelTool>
    
    return (
        <LeftPanelBtn to={`/project/${project.id}`} active={selected.project?.id === project.id} title={project.title} {...rest}>
            {glyph}
            {project.title}
        </LeftPanelBtn>
    );
}