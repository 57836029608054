import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ToolLink = ({ to, icon, title, className, ...rest }) => {
        
        return (
            <Link to={to} className={`btn btn-outline-secondary ${className}`} title={title} {...rest}>
                    <FontAwesomeIcon icon={icon} />
            </Link>
        );
}