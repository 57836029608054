import "../../css/header.sass";
import {Link} from "react-router-dom";
import {CurrentLink} from "./CurrentLink";
import {Offcanvas} from "./Offcanvas";
import {UserMenu} from "../account/UserMenu";

export const Header = () => {

    return  <header className="header">
                <nav className="navbar fixed-top bg-dark shadow" data-bs-theme="dark">
                    <div className="container-fluid">
                        <Link to="/inbox" className="navbar-brand">
                            <img className="logoicon" src="/images/logo32.png" alt="logo" />
                        </Link>
                        <Link to="/" className="navbar-brand text-muted d-none d-md-inline-flex">
                            ГэТэДэшка
                        </Link>
                        <CurrentLink className="navbar-text header-link" />
                        <span className="m-auto">{/*filler*/}</span>
                        <UserMenu className="d-none d-md-inline-flex header-link"/>
                        {/*<InactiveProjectsSwitch className="d-inline-flex d-md-none header-mobile-btn" classOn="on" />*/}
                        <button type="button" className="navbar-toggler d-inline-flex d-md-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </nav>
                <Offcanvas/>
            </header>;
}