export const GroupThingsBy = {

    nothing: "nothing",
    project: "project",
    context: "context",
    scheduler: "scheduler",
    period: "period"
}

export const By = {

    scheduler: thing => thing.schedulerType,
    context: thing => thing.contextId
}

export const groupBy = (xs, keyGetter) => {

    return xs.reduce((map, x) => {

        const key = keyGetter(x);
        const collection = map.get(key);
        collection ? collection.push(x) : map.set(key, [x])

        return map;

    }, new Map());
};