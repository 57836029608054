import {Header} from "./features/header/Header";
import {LeftPanel} from "./features/left-panel/LeftPanel";
import {Outlet} from "react-router-dom";

export const Layout = () => {

    return (
      <div>
          <Header />
          <div className="middle">
              <main id="main-panel" className="content">
                  <Outlet />
                  {/*<Routes>*/}
                  {/*    <Route path="completed" element={<Completed/>} />*/}
                  {/*    <Route path="deleted" element={<Deleted/>} />*/}
                  {/*    */}
                  {/*    */}
                  {/*    <Route path={this.props.match.url + 'inbox'} component={Inbox} />*/}
                  {/*    <Route path={this.props.match.url + 'next'} component={Next}></Route>*/}
                  {/*    <Route path={this.props.match.url + 'tomorrow'} component={Tomorrow}></Route>*/}
                  {/*    <Route path={this.props.match.url + 'scheduled'} component={Scheduled}></Route>*/}
                  {/*    <Route path={this.props.match.url + 'someday'} component={Someday}></Route>*/}
                  {/*    <Route path={this.props.match.url + 'waiting'} component={Waiting}></Route>*/}
                  {/*    <Route path={this.props.match.url + 'completed'} component={Completed}></Route>*/}
                  {/*    <Route path={this.props.match.url + 'deleted'} component={Deleted}></Route>*/}
                  {/*    */}
                  {/*    <Switch>*/}
                  {/*        <Route path={this.props.match.url + 'project/create'} component={ProjectCreate}></Route>*/}
                  {/*        <Route path={this.props.match.url + 'project/edit/:id'} component={ProjectEdit}></Route>*/}
                  {/*        <Route path={this.props.match.url + 'project/share/:id'} component={ProjectShare}></Route>*/}
                  {/*        <Route path={this.props.match.url + 'project/:id'} component={ProjectThings}></Route>*/}
                  {/*    */}
                  {/*        <Route path={this.props.match.url + 'context/create'} component={ContextCreate}></Route>*/}
                  {/*        <Route path={this.props.match.url + 'context/edit/:id'} component={ContextEdit}></Route>*/}
                  {/*        <Route path={this.props.match.url + 'context/share/:id'} component={ContextShare}></Route>*/}
                  {/*        <Route path={this.props.match.url + 'context/join/:id'} component={ContextJoin}></Route>*/}
                  {/*        <Route path={this.props.match.url + 'context/:id'} component={ContextThings}></Route>*/}
                  {/*    */}
                  {/*        <Route path={this.props.match.url + 'thing/edit/:id'} component={ThingEdit}></Route>*/}
                  {/*        <Route path={this.props.match.url + 'thing/:id'} component={ThingDetails}></Route>*/}
                  {/*    */}
                  {/*        <Route path={this.props.match.url + 'thingplanner/edit/:id'} component={ThingPlannerEdit}></Route>*/}
                  {/*        <Route path={this.props.match.url + 'thingplanner/:id'} component={ThingPlannerDetails}></Route>*/}
                  {/*    </Switch>*/}
                  {/*</Routes>*/}
              </main>
              <LeftPanel />
          </div>
      </div>
    );
}
