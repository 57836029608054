import "../../css/login.sass"
import {Form, Link, Navigate} from "react-router-dom";
import {useRegisterMutation} from "./accountApiSlice";

export const Register = () => {

    const [ register, { isFetching, isSuccess } ] = useRegisterMutation();
    
    const handleSubmit = async (event) => {
        
        event.preventDefault();
        
        const userData = {
            username: event.target[0].value,
            password: event.target[1].value,
            repeatPassword: event.target[2].value
        }
        
        await register(userData).unwrap();
    }
    
    return (
        isFetching
        ? <span>Loading</span>
        : isSuccess
        ? <Navigate replace to="/"/>
        : <div className="centering-wrapper">
            <div className="centered">
                <Form onSubmit={handleSubmit}>
                    <div className="form-floating mb-2">
                        <input className="form-control" type="text" id="username" placeholder="name@example.com"/>
                        <label htmlFor="username">Электропочта</label>
                    </div>
                    <div className="form-floating mb-2">
                        <input className="form-control" type="password" id="password" placeholder="password"/>
                        <label htmlFor="password">Пароль</label>
                    </div>
                    <div className="form-floating mb-2">
                        <input className="form-control" type="password" id="repeat-password" placeholder="repeat password"/>
                        <label htmlFor="repeat-password">Повторите пароль</label>
                    </div>
                    <div className="mb-2">
                        <button className="btn btn-outline-secondary form-control" type="submit">Зарегистрировать</button>
                    </div>
                </Form>
                <Link to="/login" className="centered" >Вход</Link>
            </div>
        </div>
    );
}