import {apiSlice} from "../../api/apiSlice";
import {Tags} from "../../api/constants";
import {createEntityAdapter, createSelector} from "@reduxjs/toolkit";
import {Scheduler} from "../../schedulers/schedulers";
import {isBeforeToday, isToday, isTomorrow} from "../../../common/date-time";
import {By} from "../../../common/sortings";

const thingsAdapter = createEntityAdapter({
    sortComparer: By.createdDesc
});

const initialState = thingsAdapter.getInitialState();

const thingApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        
        getThings: builder.query({
            query: _ => ({
                url: "/thing",
                method: "GET"
            }),
            transformResponse: data => {
                
                const updated = data.map(thing => {
                    
                    const scheduler =
                        thing.schedulerType === Scheduler.scheduled 
                            ? isToday(thing.start) || isBeforeToday(thing.start)
                                ? Scheduler.today 
                                : isTomorrow(thing.start)
                                    ? Scheduler.tomorrow
                                    : Scheduler.scheduled
                            : thing.schedulerType;
                    
                    return {
                        ...thing,
                        schedulerType: scheduler
                    }
                });
                
                return thingsAdapter.setAll(initialState, updated);
            },
            providesTags: [Tags.things]
        }),

        createThing: builder.mutation({
            query: thing => ({
                url: "/thing",
                method: "POST",
                body: thing
            }),
            invalidatesTags: [Tags.things]
        }),

        updateThing: builder.mutation({
            query: thing => ({
                url: "/thing",
                method: "PUT",
                body: thing
            }),
            invalidatesTags: [Tags.things]
        }),

        deleteThing: builder.mutation({
            query: thing => ({
                url: "/thing",
                method: "DELETE",
                body: thing
            }),
            invalidatesTags: [Tags.things]
        }),

        setThingDone: builder.mutation({
            query: done => ({
                url: "/thing/setdone",
                method: "PUT",
                body: done
            }),
            invalidatesTags: [Tags.things]
        })
    })
})

export const {
    useGetThingsQuery,
    useCreateThingMutation,
    useUpdateThingMutation,
    useDeleteThingMutation,
    useSetThingDoneMutation,
} = thingApiSlice;

export const useGetThingsQuerySubscription = thingApiSlice.endpoints.getThings.useQuerySubscription;

// selectors

export const selectThingsResult = thingApiSlice.endpoints.getThings.select();

const selectThingsData = createSelector(
    selectThingsResult,
    thingsResult => thingsResult.data
);

export const {
    selectAll: selectAllThings,
    selectById: selectThingById
} = thingsAdapter.getSelectors(state => selectThingsData(state) ?? initialState);

export const selectThingsByScheduler = createSelector(
    (state, _) => selectThingsData(state),
    (_, schedulerId) => schedulerId,
    (things, schedulerId) => {
        
        return things?.ids
                .filter(id => things.entities[id].schedulerType === schedulerId)
                .map(id => things.entities[id])
            ?? [];
    }
)

export const selectThingsByContext = createSelector(
    (state, _) => selectThingsData(state),
    (_, contextId) => contextId,
    (things, contextId) => {

        return things?.ids
                .filter(id => things.entities[id].contextId === Number(contextId))
                .map(id => things.entities[id])
            ?? [];
    }
)

export const selectThingsByProject = createSelector(
    (state, _) => selectThingsData(state),
    (_, projectId) => projectId,
    (things, projectId) => {

        return things?.ids
                .filter(id => things.entities[id].projectId === Number(projectId))
                .map(id => things.entities[id])
            ?? [];
    }
)