import {icon, regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";

export const Glyphicon = {

    briefcase: solid("briefcase"),
    calendar: solid("calendar-day"),
    complete: solid("check"),
    delete: regular("trash-can"),
    description: solid("file"),
    edit: solid("pen"),
    inbox: solid("inbox"),
    link: solid("link"),
    mapMarker: solid("location-dot"),
    menuHamburger: 'glyphicon glyphicon-menu-hamburger',
    off: solid("power-off"),
    pause: solid("pause"),
    play: solid("play"),
    plus: solid("plus"),
    repeat: 'glyphicon glyphicon-repeat',
    share: solid("share-from-square"), // do not use "share", it's invisible in FireFox (only with prod environment)
    trash: solid("trash-can"),
    uncomplete: icon({ name: "square" }),
    undelete: icon({ name: "trash-can-arrow-up" }),
    unlink: icon({ name: "link-slash" }),
}