import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const LeftPanelGroup = ({ icon, title, children }) => {

    return (
        <li className="d-flex flex-row align-items-center text-muted mt-2">
            <FontAwesomeIcon icon={icon} className="me-1" />
            <span className="me-auto fw-bold">{title}</span>
            {children}
        </li>);
};