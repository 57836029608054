import {Glyphicon} from "../../common/glyphs";

export const Scheduler = {

    inbox: "Inbox",
    next: "Next",
    today: "Today",
    tomorrow: "Tomorrow",
    scheduled: "Scheduled",
    someday: "Someday",
    waiting: "Waiting",

    create: (schedulerId) => {
        
        return {
            id: schedulerId,
            type: schedulerId,  // TODO get rid of type field
            title: Scheduler.getTitle(schedulerId),
            icon: Scheduler.getIconName(schedulerId)
        }
    },
    
    getIndex: function (scheduler) {

        switch (scheduler) {

            case Scheduler.inbox:
                return 0;

            case Scheduler.today:
                return 1;

            case Scheduler.next:
                return 2;

            case Scheduler.tomorrow:
                return 3;

            case Scheduler.scheduled:
                return 4;

            case Scheduler.waiting:
                return 5;

            case Scheduler.someday:
                return 6;
        }
    },

    getByIndex: function (index) {

        switch (index) {

            case 0:
                return Scheduler.inbox;

            case 1:
                return Scheduler.next;

            case 2:
                return Scheduler.today;

            case 3:
                return Scheduler.tomorrow;

            case 4:
                return Scheduler.scheduled;

            case 5:
                return Scheduler.someday;

            case 6:
                return Scheduler.waiting;
        }
    },

    getTitle: function (scheduler) {

        switch (scheduler) {

            case Scheduler.inbox:
                return "Входящие";

            case Scheduler.today:
                return "Сегодня";

            case Scheduler.next:
                return "Следующие";

            case Scheduler.tomorrow:
                return "Завтра";

            case Scheduler.scheduled:
                return "Запланировано";

            case Scheduler.someday:
                return "Когда-нибудь";

            case Scheduler.waiting:
                return "Ожидающие";
        }
    },

    getIconName: schedulerType => {

        if (schedulerType === Scheduler.inbox) return "inbox";

        return "calendar";
    },
    
    getIcon: schedulerType => {
        
        return Glyphicon[Scheduler.getIconName(schedulerType)];
    },

    compare: function (a, b) {

        if (!a && !b) return 0;
        if (!a) return -1;
        if (!b) return 1;
        return Scheduler.getIndex(a) - Scheduler.getIndex(b);
    },

    getUrlPath: function(scheduler) {

        return scheduler === Scheduler.today ? '' : scheduler;
    }
}

export const Schedulers = [
    {
        id: Scheduler.inbox,
        title: Scheduler.getTitle(Scheduler.inbox),
        icon: Scheduler.getIconName(Scheduler.inbox)
    },
    {
        id: Scheduler.today,
        title: Scheduler.getTitle(Scheduler.today),
        icon: Scheduler.getIconName(Scheduler.today)
    },
    {
        id: Scheduler.next,
        title: Scheduler.getTitle(Scheduler.next),
        icon: Scheduler.getIconName(Scheduler.next)
    },
    {
        id: Scheduler.tomorrow,
        title: Scheduler.getTitle(Scheduler.tomorrow),
        icon: Scheduler.getIconName(Scheduler.tomorrow)
    },
    {
        id: Scheduler.scheduled,
        title: Scheduler.getTitle(Scheduler.scheduled),
        icon: Scheduler.getIconName(Scheduler.scheduled)
    },
    {
        id: Scheduler.waiting,
        title: Scheduler.getTitle(Scheduler.waiting),
        icon: Scheduler.getIconName(Scheduler.waiting)
    },
    {
        id: Scheduler.someday,
        title: Scheduler.getTitle(Scheduler.someday),
        icon: Scheduler.getIconName(Scheduler.someday)
    }
];

export const GroupedSchedulers = [
    {
        title: "Сбор",
        icon: Scheduler.getIconName(Scheduler.inbox),
        items: [
            {
                type: Scheduler.inbox,
                title: Scheduler.getTitle(Scheduler.inbox),
                icon: Scheduler.getIconName(Scheduler.inbox)
            }
        ]
    },
    {
        title: "Планирование",
        icon: Scheduler.getIconName(Scheduler.today),
        items: [
            {
                type: Scheduler.today,
                title: Scheduler.getTitle(Scheduler.today),
                icon: Scheduler.getIconName(Scheduler.today)
            },
            {
                type: Scheduler.next,
                title: Scheduler.getTitle(Scheduler.next),
                icon: Scheduler.getIconName(Scheduler.next)
            },
            {
                type: Scheduler.tomorrow,
                title: Scheduler.getTitle(Scheduler.tomorrow),
                icon: Scheduler.getIconName(Scheduler.tomorrow)
            },
            {
                type: Scheduler.scheduled,
                title: Scheduler.getTitle(Scheduler.scheduled),
                icon: Scheduler.getIconName(Scheduler.scheduled)
            },
            {
                type: Scheduler.waiting,
                title: Scheduler.getTitle(Scheduler.waiting),
                icon: Scheduler.getIconName(Scheduler.waiting)
            },
            {
                type: Scheduler.someday,
                title: Scheduler.getTitle(Scheduler.someday),
                icon: Scheduler.getIconName(Scheduler.someday)
            }
        ]
    }
];