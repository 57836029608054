import {RegularExpression} from "../../common/regular-expressions";
import {splitBy} from "../../common/split-by";
import {useNavigate} from "react-router-dom";
import {CompleteThingBtn} from "./tools/CompleteThingBtn";
import {UncompleteThingBtn} from "./tools/UncompleteThingBtn";
import {UndeleteThingBtn} from "./tools/UndeleteThingBtn";
import {ThingLabels} from "./ThingLabels";

export const Thing = ({ thing, hideLabels, onComplete }) => {
    
    const navigate = useNavigate();
    
    if (!thing) return null;
    
    const handleClick = (event) => {
        
        event.stopPropagation();
        navigate(`/thing/${thing.id}`);
    }
    
    const title = RegularExpression.url.test(thing.title) ? splitBy.urls(thing.title) : thing.title;
    
    const tool =
        thing.deleted
            ? <UndeleteThingBtn entity={thing} />
        : thing.done
            ? <UncompleteThingBtn entity={thing}/>
            : <CompleteThingBtn thing={thing} onComplete={onComplete} />

    return (
        <div className="thing-row">
            <div className="thing-tools">
                {tool}
            </div>
            <div className="thing-link" onClick={handleClick}>{/* onClick потому что <a></a> тут нельзя, в заголовке могут быть ссылки, ссылки не должны быть вложены в другие ссылки */}
                <div className="thing-caption">{title}</div>
                <div><ThingLabels thing={thing} hideLabels={hideLabels} /></div>
            </div>
        </div>
    );
}


// export class Thing extends Component{
//
//     constructor(props){
//         super(props);
//         this.handleClick = this.handleClick.bind(this);
//     }
//     handleClick() {
//
//         this.props.onClick && this.props.onClick(this.props.thing);
//     };
//
//     render() {
//
//         var thing = this.props.thing;
//         var hideLabels = {
//             project: !thing.projectId || this.props.hideLabels && this.props.hideLabels.project,
//             context: !thing.contextId || this.props.hideLabels && this.props.hideLabels.context
//         };
//
//         var tool;
//
//         if (thing.deleted) {
//
//             tool = <ToolForm entity={thing} onSubmit={this.props.onUndelete} glyphClass={Glyphicon.undelete} title="Восстановить" />;
//
//         } else if (thing.done) {
//
//             tool = <UncompleteThingBtn entity={thing}/>;
//
//         } else {
//
//             tool =  <CompleteThingBtn entity={thing} />;
//         };
//
//         var title = Common.testUrl(thing.title) ? Common.splitByUrls(thing.title) : thing.title;
//
//         var deleteDate = new Date(thing.deleteDate);
//         var doneDate = new Date(thing.doneDate);
//         var startDate = new Date(thing.start);
//         var deleteDateLabel;
//         var doneDateLabel;
//         var startDateLabel;
//         var descriptionLabel;
//         var contextLabel;
//         var projectLabel;
//
//         if (thing.deleted) deleteDateLabel = <ThingLabel glyphClass={Glyphicon.trash} text={formatDate(deleteDate) } />;
//         if (thing.done) doneDateLabel = <ThingLabel glyphClass={Glyphicon.complete} text={formatDate(doneDate) }/>;
//         if (thing.description) descriptionLabel = <ThingLabel glyphClass={Glyphicon.description} title={thing.description.length > 100 ? thing.description.substr(0, 100) + "..." : thing.description} />;
//
//         if (thing.start) {
//
//             var today = new Date();
//             var todayStart = new Date(today.getFullYear(), today.getMonth(), today.getDate());
//             var colorName = !thing.done && new Date(thing.start) < todayStart ? LabelColor.danger : LabelColor.def;
//             startDateLabel = <ThingLabel color={colorName} glyphClass={Glyphicon.calendar} text={formatDate(startDate)}/>;
//         }
//
//         if (!hideLabels.context) {
//
//             var context = this.props.getContext(thing.contextId);
//
//             if (context) {
//
//                 contextLabel = <ThingLabel glyphClass={Glyphicon.mapMarker} text={context.title}/>;
//             }
//         }
//
//         if (!hideLabels.project) {
//
//             var project = this.props.getProject(thing.projectId);
//
//             if (project) {
//
//                 projectLabel = <ThingLabel glyphClass={Glyphicon.briefcase} text={project.title}/>;
//             }
//         }
//
//         return  <div className="thing-row">
//                     <div className="thing-tools">
//                         {tool}
//                     </div>
//                     <NavLink to={`/thing/${thing.id}`}>
//                         <div className="thing-link" onClick={this.handleClick}>
//                             <div>
//                                 <div className="thing-caption">{title}</div>
//                                 <div>{deleteDateLabel} {doneDateLabel} {startDateLabel} {descriptionLabel} {contextLabel} {projectLabel}{/* В одну строку через пробел, чтобы метки не «слипались». */}</div>
//                             </div>
//                         </div>
//                     </NavLink>
//                 </div>;
//     }
// }
//
// function mapDispatchToProps(dispatch) {
//    
//     return {
//        
//         onComplete: function(thing) {
//
//             dispatch(ActionCreator.thingComplete(thing));
//         },
//
//         onUncomplete: function (thing) {
//
//             dispatch(ActionCreator.thingUncomplete(thing));
//         },
//
//         onUndelete: function (thing) {
//
//             dispatch(ActionCreator.thingUndelete(thing));
//         }
//     }
// }
//
// Thing = connect(null, mapDispatchToProps)(Thing);