import {CompleteProjectBtn} from "./CompleteProjectBtn";
import {DeactivateProjectBtn} from "./DeactivateProjectBtn";
import {ActivateProjectBtn} from "./ActivateProjectBtn";
import {EditProjectBtn} from "./EditProjectBtn";
import {ShareProjectBtn} from "./ShareProjectBtn";

export const ProjectToolbar = ({ project }) => {

    const activateTool = project.isActive
        ? <DeactivateProjectBtn project={project} />
        : <ActivateProjectBtn project={project} />
    
    return (
        <>
            <div className="btn-toolbar">
                <span className="btn toolbar-caption" title={project.title} data-bs-toggle="collapse" data-bs-target="#project-description" aria-expanded="false" aria-controls="project-description">
                    {project.title}
                </span>
                <EditProjectBtn project={project} className="me-1" />
                {/* TODO */}
                {/*<CompleteProjectBtn project={project} className="me-1" />*/}
                {/*<ShareProjectBtn project={project} className="me-1" />*/}
                {activateTool}
            </div>
            {project.description ? <pre className="collapse" id="project-description">{project.description}</pre> : null}
        </>
    );
}