import {guid} from "./guid";
import {RegularExpression} from "./regular-expressions";
import {OuterLink} from "../features/shared/OuterLink";

export const splitBy = function() {

    return {

        newLine: function(text) {

            return text.split(RegularExpression.newLine);
        },

        urls: function(text) {

            const parts = text.split(RegularExpression.url);

            const elements = [];

            for (let i = 0; i < parts.length; i = i + 10) {

                elements.push(<span key={guid() }>{parts[i]}</span>);

                if (i < parts.length - 1) {

                    const url = (parts[i + 1] || 'http') + '://' +    // протокол
                        (parts[i + 2] ? parts[i + 2] + '@' : '') +  // имя пользователя:пароль
                        (parts[i + 3] || '') +                      // домен
                        (parts[i + 4] ? ':' + parts[i + 4] : '') +  // порт
                        (parts[i + 5] || '') +                      // путь
                        (parts[i + 6] || '') +                      // имя файла
                        (parts[i + 7] || '') +                      // расширение
                        (parts[i + 8] ? '?' + parts[i + 8] : '') +  // параметры
                        (parts[i + 9] ? parts[i + 9] : '');         // якорь

                    elements.push(<OuterLink key={guid()} href={url} target="_blank">{parts[i + 3]}</OuterLink>);
                }
            }

            return elements;
        }
    }
}();