import {useEffect} from "react";
import {GroupThingsBy} from "../../common/groupping";
import {SortThingsBy} from "../../common/sortings";
import {useDispatch, useSelector} from "react-redux";
import {selectedChanged} from "../common/commonSlice";
import {Scheduler} from "./schedulers";
import {selectThingsByScheduler} from "../things/slices/thingApiSlice";
import {ThingCreate} from "../things/ThingCreate";
import {Things} from "../things/Things";

export const ThingsByScheduler = ({ schedulerId }) => {
    
    const dispatch = useDispatch();
    
    useEffect(() => {
        
        dispatch(selectedChanged({ scheduler: Scheduler.create(schedulerId) }));
    }, [ schedulerId ]);
    
    const things = useSelector(state => selectThingsByScheduler(state, schedulerId));
    
    const groupBy = schedulerId === Scheduler.scheduled
        ? GroupThingsBy.period
        : GroupThingsBy.context;
    
    return (
        <div className="col-xs-12">
            <ThingCreate />
            <Things things={things}
                    hideLabels={{scheduler: true, context: true}}
                    groupBy={groupBy}
                    sortBy={SortThingsBy.createdDesc} />
        </div>
    );
}