import {SchedulerList} from "../schedulers/SchedulerList";
import {LeftPanelContexts} from "../contexts/LeftPanelContexts";
import {LeftPanelProjects} from "../projects/LeftPanelProjects";
import {UserMenu} from "../account/UserMenu";

export const Offcanvas = () => {
    
    return <>
        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">ГэТэДэшка</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNavbar" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <ul className="nav nav-pills flex-column">
                    <SchedulerList data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNavbar" />
                    <LeftPanelContexts data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNavbar" />
                    <LeftPanelProjects data-bs-dismiss="offcanvas" data-bs-target="#offcanvasNavbar" />
                </ul>
                <hr />
                <UserMenu />
            </div>
        </div>
    </>;
}