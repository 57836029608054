import {Component} from "react";
import {GroupThingsBy} from "../../common/groupping";
import {Filter} from "../../common/filters";
import {ActionCreator} from "../../features/actions";
import {connect} from "react-redux";
import {Things} from "../../features/things/Things";

export class Completed extends Component {

    constructor(props) {
        super(props);
        
        this.handleThingClick = this.handleThingClick.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this);
        this.componentWillMount = this.componentWillMount.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }
    
    handleThingClick(thing) {

        this.props.history.push('/thing/' + thing.id, { referer: this.props.location });
    }

    handleMoreClick() {

        this.props.onLoading && this.props.onLoading();
        this.props.things &&
            this.props.things.pagesLoaded &&
            this.props.onLoad &&
            this.props.onLoad(this.props.things.pagesLoaded + 1);
    }

    componentWillMount() {

        this.props.things && this.props.things.didInvalidate && this.props.onLoad && this.props.onLoad(1);
    }

    componentDidMount() {

        if (this.props.onGroupSelected) this.props.onGroupSelected({
            filter: {
                type: Filter.completed,
                title: Filter.getTitle(Filter.completed),
                icon: Filter.getIconName(Filter.completed)
            }
        });
    }

    render() {
        
        if (!this.props.things) return null;

        const loading = <h4 className="well well-sm">Загрузка...</h4>;
        const more = <div className="more-row" onClick={this.handleMoreClick}><h4>Ещё</h4></div>;

        const things = this.props.things.items.length === 0 && this.props.things.isFetching
            ? null
            : <Things things={this.props.things.items}
                      onThingClick={this.handleThingClick}
                      groupBy={GroupThingsBy.nothing}
                      getContext={this.props.getContext}
                      getProject={this.props.getProject}/>;

        return <div className="col-xs-12">
                    {things}
                    {(this.props.things.isFetching ? loading : (this.props.things.pagesLoaded >= this.props.things.pageCount ? null : more))}
                </div>;
    }
}

function mapStateToProps(state) {

    return {

        things: state.things.completed,

        getContext: function (id) {

            for (let i = 0; i < state.contexts.length; i++) {

                if (state.contexts[i].id === id) return state.contexts[i];
            }

            return undefined;
        },

        getProject: function (id) {
            for (let i = 0; i < state.projects.length; i++) {

                if (state.projects[i].id === id) return state.projects[i];
            }

            return undefined;
        }
    }
}

function mapDispatchToProps(dispatch) {

    return {

        onLoading: function () {

            dispatch(ActionCreator.thingCompletedLoading());
        },

        onLoad: function (pageNumber) {

            dispatch(ActionCreator.thingCompletedLoad(pageNumber));
        },

        onGroupSelected: function (selected) {

            dispatch(ActionCreator.commonSelectedChange(selected));
        }
    }
}

Completed = connect(mapStateToProps, mapDispatchToProps)(Completed);

