import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    hideInactiveProjects: true,
    selected: { } // TODO get selected (from URL?)
}

const selectedChangedReducer = (state, action) => {
    state.selected = action.payload;
}

const setHideInactiveProjectsReducer = (state, action) => {
    state.hideInactiveProjects = action.payload;
}

const commonSlice = createSlice({
    name: "common",
    initialState: initialState,
    reducers: {
        selectedChanged: selectedChangedReducer,
        setHideInactiveProjects: setHideInactiveProjectsReducer
    }
});

export const {
    selectedChanged,
    setHideInactiveProjects} = commonSlice.actions;

export const commonReducer = commonSlice.reducer;

// selectors

export const selectHideInactiveProjects = state => state.common.hideInactiveProjects;
export const selectSelected = state => state.common.selected;