import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    title: "",
    description: ""
}

const titleChangedReducer = (state, action) => {
    
    state.title = action.payload;
}

const descriptionChangedReducer = (state, action) => {
    
    state.description = action.payload;
}

const resetReducer = (state) => {
    
    state.title = "";
    state.description = "";
}

const newProjectSlice = createSlice({
    name: "newProject",
    initialState: initialState,
    reducers: {
        titleChanged: titleChangedReducer,
        descriptionChanged: descriptionChangedReducer,
        resetNewProject: resetReducer
    }
});

export const {
    titleChanged,
    descriptionChanged,
    resetNewProject
} = newProjectSlice.actions;

export const newProjectReducer = newProjectSlice.reducer;