import {Glyphicon} from "../../common/glyphs";
import {ContextList} from "./ContextList";
import {useGetContextsQuery} from "./slices/contextApiSlice";
import {LeftPanelGroup} from "../left-panel/LeftPanelGroup";
import {LeftPanelTool} from "../left-panel/LeftPanelTool";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const LeftPanelContexts = ({...rest}) => {

    const { isLoading } = useGetContextsQuery();
    
    const contexts =
        isLoading
        ? <span>Loading</span>
        : <ContextList {...rest}/>
    
    return (
        <>
            <LeftPanelGroup icon={Glyphicon.mapMarker} title="Контексты">
                <LeftPanelTool linkTo="/context/create" title="Создать контекст" {...rest}>
                    <FontAwesomeIcon icon={Glyphicon.plus} />
                </LeftPanelTool>
            </LeftPanelGroup>
            {contexts}
        </>
    );
}