import dayjs from "dayjs";

export const By = {
  
  id: (a, b) => a.id - b.id,
  created: (a, b) => a.id - b.id,             // By create time. It equals to sorting by id because of sequential id.
  createdDesc: (a, b) => -1 * (a.id - b.id),  // By create time DESC.
  start: (a, b) => dayjs.utc(a.start) - dayjs.utc(b.start)
}


// TODO remove/refactor
export const SortThingsBy = {

  created: 'created',
  createdDesc: 'created-descending',
  //start: 'start',

  getSortFunc: function(sortBy) {

    switch (sortBy) {

      case SortThingsBy.createdDesc:

        return function (a, b) {
          return new Date(b.created) - new Date(a.created);
        }

      default:

        return function (a, b) {
          return new Date(a[sortBy]) - new Date(b[sortBy]);
        }

        //case SortThingsBy.start:
        //    return function(a, b) {
        //        return new Date(a.start) - new Date(b.start);
        //    }
    }
  }
}