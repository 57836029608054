import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const CreateThingDropdown = ({ entities, currentId, text, icon, allowEmpty=true, onSelect, className }) => {
    
    const current = entities.find(entity => entity.id === currentId);
    
    const handleClickFactory = (entity) => {

        return (event) => {

            event.preventDefault();
            onSelect(entity);
        }
    }
    
    return (
        <span className={`dropdown ${className}`}>
            <span className="badge bg-secondary dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <FontAwesomeIcon icon={icon}/>
                <span className="ms-1">{current?.title ?? text}</span>
            </span>
            <ul className="dropdown-menu prj-dropdown">
                {
                    allowEmpty
                        ?   <li key="empty">
                                <a href="#" onClick={handleClickFactory(null)} className="dropdown-item">
                                    <FontAwesomeIcon icon={icon} className="me-1"/>
                                    {text}
                                </a>
                            </li>
                        : null
                }
                {entities.map(entity => <li key={entity.id} className={entity.id === currentId ? "active" : ""}><a href="#" onClick={handleClickFactory(entity)} className="dropdown-item">{entity.title}</a></li>)}
            </ul>
        </span>
    );
}