import {LabelColor} from "../../common/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const ThingLabel = ({ color, icon, title, text, className }) => {

    const colorClass = "bg-" + (color ?? LabelColor.default);
    
    return (
        <span className={`badge ${colorClass} ${className}`} title={title ?? text}>
            <FontAwesomeIcon icon={icon} className="me-1"/>
            {text}
        </span>);
}