import {useSelector} from "react-redux";
import {selectAccount, useLogoutMutation} from "./accountApiSlice";
import {Link} from "react-router-dom";

export const UserMenu = ({className}) => {

    const account = useSelector(state => selectAccount(state));
    const [ logout ] = useLogoutMutation();
    
    const handleLogoutClick = async (event) => {
        
        event.preventDefault();
        await logout();
    }

    return <>
        {/*    <Link to="/account" className={`me-4 navbar-text d-md-inline-flex ${className}`} title="Учетная запись">{account.userName}</Link>*/}
        <Link to="#" onClick={handleLogoutClick} className={`navbar-text d-md-inline-flex ${className}`}>Выход</Link>{/* TODO get rid of <a></a> */}
    </>;
}