import {ThingPlannerFilterType} from "../../common/filters";
import {Actions} from "../actions";

export const thingPlannerReducer = function (state, action) {

    if (state === undefined || state === null)
        return {
            items: [],
            filter: {
                type: ThingPlannerFilterType.hideAll,
                projectId: null,
                contextId: null
            }
        }

    switch (action.type) {

        case Actions.thingPlannerListLoad:
            
            return Object.assign({}, state, { items: action.payload.thingPlanners });

        case Actions.thingPlannerCreate:

            if (!action.payload || !action.payload.thingPlanner) break;
            return Object.assign({}, state, {
                items: [action.payload.thingPlanner].concat(state.items)
            });

        case Actions.thingPlannerComplete:

            return Object.assign({}, state, {
                items: state.items.filter(function (tp) { return tp.id !== action.payload.thingPlanner.id })
            });

        case Actions.thingPlannerShowAll:

            return Object.assign({},
                state,
                {
                    filter: {
                        type: ThingPlannerFilterType.showAll,
                        projectId: null,
                        contextId: null
                    }
                });

        case Actions.thingPlannerHideAll:

            return Object.assign({}, state, {
                filter: {
                    type: ThingPlannerFilterType.hideAll,
                    projectId: null,
                    contextId: null
                }
            });

        case Actions.thingPlannerShowProject:

            return Object.assign({},
                state,
                {
                    filter: {
                        type: ThingPlannerFilterType.showProject,
                        projectId: action.payload.project.id,
                        contextId: null
                    }
                });

        case Actions.thingPlannerShowContext:

            // TODO show planners for joined contexts

            return Object.assign({},
                state,
                {
                    filter: {
                        type: ThingPlannerFilterType.showContext,
                        projectId: null,
                        contextId: action.payload.context.id
                    }
                });

        case Actions.thingPlannerEdit:

            if (!action.payload || !action.payload.thingPlanner) break;
            return Object.assign({},
                state,
                {
                    items: state.items.map(tp => tp.id === action.payload.thingPlanner.id ? action.payload.thingPlanner : tp)
                });


    }

    return state;
}