import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    title: ""
}

const titleChangedReducer = (state, action) => {
    
    state.title = action.payload;
}

const newContextSlice = createSlice({
    name: "newContext",
    initialState: initialState,
    reducers: {
        titleChanged: titleChangedReducer,
    }
})

export const { titleChanged } = newContextSlice.actions;
export const newContextReducer = newContextSlice.reducer;