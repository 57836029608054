import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {Tags} from "./constants";

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: "/api",
        //baseUrl: "https://gtdshka.ru/api",
        //baseUrl: "http://localhost:8080",
        credentials: "include",
        tagTypes: Tags.getAll()
    }),
    endpoints: builder => ({
    })
})